import React, { Component } from 'react';
import '../App.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

class Loading extends Component {
  render() {
    return (
      <div>
        <h2 style={{direction: 'rtl'}}>טוען..</h2>
        <CircularProgress />
      </div>
    );
  }
}

export default Loading;
