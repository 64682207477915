import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from '../components/DrawerContent';

const headers = {
  'Id': 'Id',
  'FirstName': 'FirstName',
  'LastName': 'LastName',
  'Printing': 'Printing',
  'Backup': 'Backup',
  'AllowStudentChanges': 'AllowStudentChanges',
  'AllowEventChanges': 'AllowEventChanges',
  'backupVersion': 'backupVersion',
  'status': 'status',
  'City': 'City',
  'ClientStatusMessage': 'ClientStatusMessage',
  'UserDeviceNumber': 'UserDeviceNumber',
  'HideCharts': 'HideCharts',
  'ProfessionalManager': 'ProfessionalManager',
  'ConvertInvoicestoExcel': 'ConvertInvoicestoExcel',
  'ReportsMenu': 'ReportsMenu',
  'WorkSummary': 'WorkSummary',
  'Bookkeeping': 'Bookkeeping',
  'BTAddress': 'BTAddress',
  'Serialnumber': 'Serialnumber',
  'UseNewBackupServer': 'UseNewBackupServer',
  'OpeningStudent': 'OpeningStudent',
  'OpenAgreement': 'OpenAgreement',
  'Settings': 'Settings',
  'ManagementTools': 'ManagementTools',
  'MaximumStudents': 'MaximumStudents',
  'MaximumRecordsBook': 'MaximumRecordsBook',
  'DatedToNoneDisplaySave': 'DatedToNoneDisplaySave',
  'OldCalendar': 'OldCalendar',
  'TerminalName': 'TerminalName',
  'Lectures': 'Lectures',
  'SivanSchool': 'Sivan-School'
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      active: [],
      inactive: [],
      table: [],
      originalTable: [],
      openCreation: false,
      deleteToast: false,
      loading: false,
      column: 'AllowStudentChanges',
      target: '',
      source: '',
      swapOpen: false,
      openDrawer: false,
      showActive: true,
      fullTable: [],
      haveNextPage: true,
      havePreviousPage: true,
      page: 0,
      active_ids: [],
      inactive_ids: []
    };
  }

  componentDidMount() {
    this.getData();
    this.getColors();
  }

  split = (arr) => {
    let len = 10;
    let chunks = [],
        i = 0,
        n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }

  getData = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/client', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        const table = res.data.data.reverse();
        let active = [];
        let active_ids = [];
        let inactive = [];
        let inactive_ids = [];
        table.forEach((item, i) => {
          let status = item.status;
          if (item.status === "1" || status === 1) {
             active.push(item);
             if (!active_ids.includes(item.Id)) active_ids.push(item.Id)
          }
          else {
            if (!inactive_ids.includes(item.Id)) {
              inactive_ids.push(item.Id);
            }
            inactive.push(item);

          }
        });
        let chunks = this.split(active);
        this.setState({inactive_ids: inactive_ids, active_ids: active_ids, page: 0, active: active, inactive: inactive, table: chunks[0], fullTable: chunks, originalTable: res.data.data.reverse(), loading: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  getColors = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/colors', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        const colors = res.data.data;
        let colorsByKeys = {};
        colors.forEach((item, i) => {
          colorsByKeys[item.name] = item.color;
        });

        this.setState({loading: false, colors: colorsByKeys})
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  delete = (row) => {
    this.setState({loading: true, deleteToast: false}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/client?rowid='+row.Rowid, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({loading: false, deleteToast: true});
        this.reset();
        this.getData();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  update = (row) => {
    this.setState({updateToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/client?rowid='+row.Rowid, {
        'Id': this.state.Id,
        'FirstName': this.state.FirstName,
        'LastName': this.state.LastName,
        'Printing': this.state.Printing,
        'Backup': this.state.Backup,
        'AllowStudentChanges': this.state.AllowStudentChanges,
        'AllowEventChanges': this.state.AllowEventChanges,
        'backupVersion': this.state.backupVersion,
        'status': this.state.status,
        'City': this.state.City,
        'ClientStatusMessage': this.state.ClientStatusMessage,
        'UserDeviceNumber': this.state.UserDeviceNumber,
        'HideCharts': this.state.HideCharts,
        'ProfessionalManager': this.state.ProfessionalManager,
        'ConvertInvoicestoExcel': this.state.ConvertInvoicestoExcel,
        'ReportsMenu': this.state.ReportsMenu,
        'WorkSummary': this.state.WorkSummary,
        'Bookkeeping': this.state.Bookkeeping,
        'BTAddress': this.state.BTAddress,
        'Serialnumber': this.state.Serialnumber,
        'UseNewBackupServer': this.state.UseNewBackupServer,
        'OpeningStudent': this.state.OpeningStudent,
        'OpenAgreement': this.state.OpenAgreement,
        'Settings':this.state.Settings,
        'ManagementTools': this.state.ManagementTools,
        'MaximumStudents': this.state.MaximumStudents,
        'MaximumRecordsBook': this.state.MaximumRecordsBook,
        'DatedToNoneDisplaySave': this.state.DatedToNoneDisplaySave,
        'OldCalendar': this.state.OldCalendar,
        'TerminalName': this.state.TerminalName,
        'Lectures': this.state.Lectures,
        'SivanSchool': this.state.SivanSchool
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({updateToast: true, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  create = (row) => {
    this.setState({createToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.post('http://api.sivan-cma.com/v1/client', {
        'Id': this.state.Id,
        'FirstName': this.state.FirstName,
        'LastName': this.state.LastName,
        'Printing': this.state.Printing,
        'Backup': this.state.Backup,
        'AllowStudentChanges': this.state.AllowStudentChanges,
        'AllowEventChanges': this.state.AllowEventChanges,
        'backupVersion': this.state.backupVersion,
        'status': this.state.status,
        'City': this.state.City,
        'ClientStatusMessage': this.state.ClientStatusMessage,
        'UserDeviceNumber': this.state.UserDeviceNumber,
        'HideCharts': this.state.HideCharts,
        'ProfessionalManager': this.state.ProfessionalManager,
        'ConvertInvoicestoExcel': this.state.ConvertInvoicestoExcel,
        'ReportsMenu': this.state.ReportsMenu,
        'WorkSummary': this.state.WorkSummary,
        'Bookkeeping': this.state.Bookkeeping,
        'BTAddress': this.state.BTAddress,
        'Serialnumber': this.state.Serialnumber,
        'UseNewBackupServer': this.state.UseNewBackupServer,
        'OpeningStudent': this.state.OpeningStudent,
        'OpenAgreement': this.state.OpenAgreement,
        'Settings':this.state.Settings,
        'ManagementTools': this.state.ManagementTools,
        'MaximumStudents': this.state.MaximumStudents,
        'MaximumRecordsBook': this.state.MaximumRecordsBook,
        'DatedToNoneDisplaySave': this.state.DatedToNoneDisplaySave,
        'OldCalendar': this.state.OldCalendar,
        'TerminalName': this.state.TerminalName,
        'Lectures': this.state.Lectures,
        'SivanSchool': this.state.SivanSchool
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({createToast: true, openCreation: false, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  edit = (row) => {
    this.setState({
      'Id': row.Id,
      'FirstName': row.FirstName,
      'LastName': row.LastName,
      'Printing': row.Printing,
      'Backup': row.Backup,
      'AllowStudentChanges': row.AllowStudentChanges,
      'AllowEventChanges': row.AllowEventChanges,
      'backupVersion': row.backupVersion,
      'status': row.status,
      'City': row.City,
      'ClientStatusMessage': row.ClientStatusMessage,
      'UserDeviceNumber': row.UserDeviceNumber,
      'HideCharts': row.HideCharts,
      'ProfessionalManager': row.ProfessionalManager,
      'ConvertInvoicestoExcel': row.ConvertInvoicestoExcel,
      'ReportsMenu': row.ReportsMenu,
      'WorkSummary': row.WorkSummary,
      'Bookkeeping': row.Bookkeeping,
      'BTAddress': row.BTAddress,
      'Serialnumber': row.Serialnumber,
      'UseNewBackupServer': row.UseNewBackupServer,
      'OpeningStudent': row.OpeningStudent,
      'OpenAgreement': row.OpenAgreement,
      'Settings':row.Settings,
      'ManagementTools': row.ManagementTools,
      'MaximumStudents': row.MaximumStudents,
      'MaximumRecordsBook': row.MaximumRecordsBook,
      'DatedToNoneDisplaySave': row.DatedToNoneDisplaySave,
      'OldCalendar': row.OldCalendar,
      'TerminalName': row.TerminalName,
      'Lectures': row.Lectures,
      'SivanSchool': row.SivanSchool,
      'chosen': row.Rowid
    });
  }

  reset = (row) => {
    this.setState({
      'Id': '',
      'FirstName': '',
      'LastName': '',
      'Printing': '',
      'Backup': '',
      'AllowStudentChanges': '',
      'AllowEventChanges': '',
      'backupVersion': '',
      'status': '',
      'City': '',
      'ClientStatusMessage': '',
      'UserDeviceNumber': '',
      'HideCharts': '',
      'ProfessionalManager': '',
      'ConvertInvoicestoExcel': '',
      'ReportsMenu': '',
      'WorkSummary': '',
      'Bookkeeping': '',
      'BTAddress': '',
      'Serialnumber': '',
      'UseNewBackupServer': '',
      'OpeningStudent': '',
      'OpenAgreement': '',
      'Settings': '',
      'ManagementTools': '',
      'MaximumStudents': '',
      'MaximumRecordsBook': '',
      'DatedToNoneDisplaySave': '',
      'OldCalendar': '',
      'TerminalName': '',
      'Lectures': '',
      'SivanSchool': '',
      'chosen': '',
    });
  }

  logout = () => {
    window.localStorage.setItem('token', '');
    window.location.reload();
  }

  resetSearch = () => {
    this.setState({table: this.split(this.state.active)[0], fullTable: this.split(this.state.active), page: 0, search: ''});
  }

  search = (value) => {
    this.setState({search: value}, () => {
      if (value === '') this.setState({table: this.split(this.state.active)[0], fullTable: this.split(this.state.active), page: 0, search: ''});
      else {
        let table_filtered = [];
        this.state.originalTable.forEach((item, i) => {
          try {
            if (
              item.Id === value ||
              item.FirstName === value ||
              item.LastName === value ||
              item.City === value ||
              item.ClientStatusMessage === value ||
              item.UserDeviceNumber === value ||
              item.BTAddress === value ||
              item.Serialnumber === value ||
              item.TerminalName === value
            ) {
              table_filtered.push(item);
            }
          } catch (err) {
            console.log(err);
          }
        });
        let chunks = this.split(table_filtered);
        if (chunks.length >= 1) this.setState({page: 0, table: chunks[0], fullTable: chunks});
      }
    });
  }

    swap = () => {
      this.setState({loading: true}, () => {
        const token = window.localStorage.getItem('token');
        return axios.put('http://api.sivan-cma.com/v1/client-swap', {
          column: this.state.column,
          target: this.state.target,
          source: this.state.source
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
          console.log(res);
          this.getData();
        }).catch(err => {
          console.log(err);
          this.setState({loading: false})
        });
      });
    }

    stringify = (text) => {
      if (text === "null" || text === null || text === undefined || text === "undefined") return '';
      return text;
    }

      sortBy = (sortBy) => {
        let data__ = this.state.fullTable;
        let data = [];
        data__.forEach((item, i) => {
          item.forEach((tfa) => {
            data.push(tfa);
          });
        });

        let data_ = data;
        this.setState({loading: true}, () => {
          if (sortBy === 'Id') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.Id) - parseInt(b.Id);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Id) - parseInt(a.Id);
              });
              this.setState({ sortBy: 'Id',})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0],});
          } else if (sortBy === 'Printing') {
            if (this.state.sortBy === 'Printing') {
              data_.sort(function (a, b) {
                return parseInt(a.Printing) - parseInt(b.Printing);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Printing) - parseInt(a.Printing);
              });
              this.setState({sortBy: 'Printing'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else if (sortBy === 'Backup') {
            if (this.state.sortBy === 'Backup') {
              data_.sort(function (a, b) {
                return parseInt(a.Backup) - parseInt(b.Backup);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Backup) - parseInt(a.Backup);
              });
              this.setState({sortBy: 'Backup'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'AllowStudentChanges') {
            if (this.state.sortBy === 'AllowStudentChanges') {
              data_.sort(function (a, b) {
                return new Date(a.AllowStudentChanges) - new Date(b.AllowStudentChanges);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return new Date(b.AllowStudentChanges) - new Date(a.AllowStudentChanges);
              });
              this.setState({sortBy: 'AllowStudentChanges'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'AllowEventChanges') {
            if (this.state.sortBy === 'AllowEventChanges') {
              data_.sort(function (a, b) {
                return new Date(a.AllowEventChanges) - new Date(b.AllowEventChanges);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return new Date(b.AllowEventChanges) - new Date(a.AllowEventChanges);
              });
              this.setState({sortBy: 'AllowEventChanges'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else if (sortBy === 'backupVersion') {
            if (this.state.sortBy === 'backupVersion') {
              data_.sort(function (a, b) {
                return parseInt(a.backupVersion) - parseInt(b.backupVersion);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.backupVersion) - parseInt(a.backupVersion);
              });
              this.setState({sortBy: 'backupVersion'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else if (sortBy === 'status') {
            if (this.state.sortBy === 'status') {
              data_.sort(function (a, b) {
                return parseInt(a.status) - parseInt(b.status);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.status) - parseInt(a.status);
              });
              this.setState({sortBy: 'status'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'UserDeviceNumber') {
            if (this.state.sortBy === 'UserDeviceNumber') {
              data_.sort(function (a, b) {
                return parseInt(a.UserDeviceNumber) - parseInt(b.UserDeviceNumber);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.UserDeviceNumber) - parseInt(a.UserDeviceNumber);
              });
              this.setState({sortBy: 'UserDeviceNumber'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else if (sortBy === 'HideCharts') {
            if (this.state.sortBy === 'HideCharts') {
              data_.sort(function (a, b) {
                return parseInt(a.HideCharts) - parseInt(b.HideCharts);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.HideCharts) - parseInt(a.HideCharts);
              });
              this.setState({sortBy: 'HideCharts'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'ReportsMenu') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.ReportsMenu) - parseInt(b.ReportsMenu);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.ReportsMenu) - parseInt(a.ReportsMenu);
              });
              this.setState({sortBy: 'ReportsMenu'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'WorkSummary') {
            if (this.state.sortBy === 'WorkSummary') {
              data_.sort(function (a, b) {
                return parseInt(a.WorkSummary) - parseInt(b.WorkSummary);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.WorkSummary) - parseInt(a.WorkSummary);
              });
              this.setState({sortBy: 'WorkSummary'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'Bookkeeping') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.Bookkeeping) - parseInt(b.Bookkeeping);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Bookkeeping) - parseInt(a.Bookkeeping);
              });
              this.setState({ sortBy: 'Bookkeeping'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0],});
          } else if (sortBy === 'UseNewBackupServer') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.UseNewBackupServer) - parseInt(b.UseNewBackupServer);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.UseNewBackupServer) - parseInt(a.UseNewBackupServer);
              });
              this.setState({sortBy: 'UseNewBackupServer'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0], });
          } else if (sortBy === 'MaximumStudents') {
            if (this.state.sortBy === 'MaximumStudents') {
              data_.sort(function (a, b) {
                return parseInt(a.Id) - parseInt(b.Id);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Id) - parseInt(a.Id);
              });
              this.setState({sortBy: 'MaximumStudents'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else if (sortBy === 'MaximumRecordsBook') {
            if (this.state.sortBy === 'MaximumRecordsBook') {
              data_.sort(function (a, b) {
                return parseInt(a.MaximumRecordsBook) - parseInt(b.MaximumRecordsBook);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.MaximumRecordsBook) - parseInt(a.MaximumRecordsBook);
              });
              this.setState({sortBy: 'MaximumRecordsBook'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else if (sortBy === 'DatedToNoneDisplaySave') {
            if (this.state.sortBy === 'DatedToNoneDisplaySave') {
              data_.sort(function (a, b) {
                return new Date(a.DatedToNoneDisplaySave) - new Date(b.DatedToNoneDisplaySave);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return new Date(b.DatedToNoneDisplaySave) - new Date(a.DatedToNoneDisplaySave);
              });
              this.setState({sortBy: 'DatedToNoneDisplaySave'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: this.split(data_)[0]});
          } else {
            console.log('?')
          }
        })
      }

      whatColor = (row) => {
        try {
          if (row.Backup === 0 || row.Backup === "0") return this.state.colors['Backup'];

          if (row.Printing === 0 || row.Printing === "0") return this.state.colors['Printing'];

          return '';
        } catch (err) {
          return '';
        }
      }

      nextPage = () => {
        let page = this.state.page;
        let chunks = this.state.fullTable.length;
        if (chunks > page + 1) {
          page += 1;
          this.setState({havePreviousPage: true, haveNextPage: chunks > page + 1, page: page, table: this.state.fullTable[page]});
        } else {
          this.setState({havePreviousPage: true, haveNextPage: false})
        }
      }

      previousPage = () => {
        let page = this.state.page;
        let chunks = this.state.fullTable.length;
        if (chunks > page - 1) {
          page -= 1;
          this.setState({haveNextPage: true, havePreviousPage: chunks > page - 1, page: page, table: this.state.fullTable[page]});
        } else {
          this.setState({haveNextPage: true, havePreviousPage: false})
        }
      }

  render() {
    const allowSortBy = [
      'Id',
      'Printing',
      'Backup',
      'backupVersion',
      'status',
      'UserDeviceNumber',
      'HideCharts',
      'ReportsMenu',
      'WorkSummary',
      'Bookkeeping',
      'UseNewBackupServer',
      'MaximumStudents',
      'MaximumRecordsBook',
    ]
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}

            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <br />
            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <h3 style={{marginBottom: 0}}><b>Sivan Client | החלפת ערכים</b></h3>

            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div>
                <div>
                  <a href="#"><p style={{fontWeight: 'bold', textDecoration: 'underline'}} onClick={(e) => this.setState({swapOpen: false})}>הסתר</p></a>
                  <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>החלף ערכים</p>

                  <p>בחר עמודה</p>
                  <Select placeholder={'עמודה'} label="עמודה" value={this.state.column} onChange={(e) => this.setState({column: e.target.value})}>
                    <MenuItem value={'AllowStudentChanges'}>AllowStudentChanges</MenuItem>
                    <MenuItem value={'AllowEventChanges'}>AllowEventChanges</MenuItem>
                  </Select>
                  <p>ערכים</p>
                  <div>
                  <TextField style={{width: 350}} label="החלף ל" value={this.state.target} onChange={(e) => this.setState({target: e.target.value})} plcaeholder={'טקסט לחיפוש כאן..'} />
                  &nbsp;&nbsp;&nbsp;
                  <TextField style={{width: 350}} label="מקור" value={this.state.source} onChange={(e) => this.setState({source: e.target.value})} plcaeholder={'טקסט לחיפוש כאן..'} />
                  </div>
                  <Button style={{marginTop: 3, marginBottom: 6}} color={'secondary'} variant={'contained'} onClick={(e) => this.swap()}>החלף</Button>

                </div>
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Home;
