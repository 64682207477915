import React, { Component } from 'react';
import '../App.css';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import axios from 'axios';

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  render() {
    return (
      <Snackbar open={this.state.open} autoHideDuration={6000} onClose={(e) => this.setState({open: false})}>
        <Alert onClose={(e) => this.setState({open: false})} severity={this.props.type}>
          {this.props.children}
        </Alert>
      </Snackbar>
    );
  }
}

export default Toast;
