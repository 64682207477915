import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import axios from 'axios';

import Loading from '../components/Loading';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: false
    };
  }

  login = () => {
    const username = this.state.username;
    const password = this.state.password;

    return axios.post('http://api.sivan-cma.com/v1/login', {
      username: username,
      password: password
    }).then(res => {
      window.localStorage.setItem('token', res.data.data);
      window.location.reload();
    }).catch(err => {
      console.log(err);
      this.setState({error: true});
    });
  }

  render() {
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            <h2><b>Sivan Client | ניהול סיון</b></h2>
            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div style={{padding: '5%'}}>
                <TextField id="username" name="username" key="username" label="Username" value={this.state.username} placeholder="Username" style={{width: 500, marginBottom: '1%'}} onChange={(e) => this.setState({username: e.target.value})}/>
                <br />
                <TextField type="password" id="password" name="password" key="password" label="Password" value={this.state.password} placeholder="Password" style={{width: 500}} onChange={(e) => this.setState({password: e.target.value})}/>
                <br /><br />
                <Button color={'primary'} variant={'contained'} onClick={(e) => this.login()}>Login</Button>
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Login;
