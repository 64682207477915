import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';

const headers = {
  'name': 'שם העיר'
};

class Cities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      table: [],
      originalTable: [],
      openCreation: false,
      loading: false,
      Cityid: '',
      name: '',
      deleteToast: false
    };
  }

  componentDidMount() {
    this.logout();
  }
  logout = () => {
    window.localStorage.setItem('token', '');
    window.location.href = '/';
  }

  render() {
    return (
      <div className="App">
        <center>
          <Loading />
        </center>
      </div>
    );
  }
}

export default Cities;
