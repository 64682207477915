import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContent from '../components/DrawerContent';
import { ChromePicker } from 'react-color';

const headers = {
  name: 'שם',
  amountWithVat: 'סכום כולל מע"מ',
  fromDate: 'מתאריך',
  toDate: 'עד תאריך',
};

class Colors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      table: [],
      originalTable: [],
      openCreation: false,
      loading: false,
      Itemid: '',
      branchCode: '',
      branchName: '',
      branchAddress: '',
      branchCity: '',
      branchPostCode: '',
      deleteToast: false,
      sortBy: '',
      colors: {
        'Backup': '',
        'Printing': '',
        'BackupPrinting': ''
      },
      printingColor: '',
      backupColor: ''
    };
  }

  componentDidMount() {
    this.getColors();
  }


  getColors = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/colors', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        const colors = res.data.data;

        let colorsByKeys = {};
        colors.forEach((item, i) => {
          colorsByKeys[item.name] = item.color;
        });
        console.log(colorsByKeys);
        this.setState({loading: false, colors: colorsByKeys, printingColor: colorsByKeys['Printing'], backupColor: colorsByKeys['Backup'], backupPrintingColor: colorsByKeys['BackupPrinting']})
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  changePrinting = (color) => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/colors/printing', { color: color }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        console.log(res);
        this.getColors();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  changeBackup = (color) => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/colors/backup', { color: color }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        console.log(res);
        this.getColors();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  changeBackupPrinting = (color) => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/colors/backup-printing', { color: color }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        console.log(res);
        this.getColors();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  render() {
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}


            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <br />

            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <h4 style={{marginBottom: 0}}><b>ניהול צבעים</b></h4>

            <p style={{direction: 'rtl'}}>צבע Printing</p>
            <ChromePicker color={this.state.printingColor} onChangeComplete={(color) => this.setState({printingColor: color.hex})}  onChange={(color) => this.setState({printingColor: color.hex})}/>
            <Button onClick={(e) => this.changePrinting(this.state.printingColor)}>שמור</Button>

            <p style={{direction: 'rtl'}}>צבע Backup</p>
            <ChromePicker color={this.state.backupColor} onChangeComplete={(color) => this.setState({backupColor: color.hex})}  onChange={(color) => this.setState({backupColor: color.hex})}/>
            <Button onClick={(e) => this.changeBackup(this.state.backupColor)}>שמור</Button>

            <p style={{direction: 'rtl'}}>צבע Backup + Printing</p>
            <ChromePicker color={this.state.backupPrintingColor} onChangeComplete={(color) => this.setState({backupPrintingColor: color.hex})}  onChange={(color) => this.setState({backupPrintingColor: color.hex})}/>
            <Button onClick={(e) => this.changeBackupPrinting(this.state.backupPrintingColor)}>שמור</Button>
            <br /><br />

          </Card>
        </center>
      </div>
    );
  }
}

export default Colors;
