import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContent from '../components/DrawerContent';

const headers = {
  bankName: 'שם הבנק',
  bankNumber: 'מספר הבנק',
};

class Banks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      table: [],
      originalTable: [],
      openCreation: false,
      loading: false,
      Bankid: '',
      bankNumber: '',
      bankName: '',
      deleteToast: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/banks', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        let d = res.data.data;
        d.sort(function (a, b) {
          return parseInt(a.bankNumber) - parseInt(b.bankNumber);
        });
        this.setState({table: d, originalTable: d, loading: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  delete = (row) => {
    this.setState({loading: true, deleteToast: false}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/banks?id='+row.Bankid, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({loading: false, deleteToast: true});
        this.reset();
        this.getData();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  update = (row) => {
    this.setState({updateToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/banks?id='+row.Bankid, {
        bankNumber: this.state.bankNumber,
        bankName: this.state.bankName,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({updateToast: true, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  create = (row) => {
    this.setState({createToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.post('http://api.sivan-cma.com/v1/banks', {
        bankNumber: this.state.bankNumber,
        bankName: this.state.bankName,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({createToast: true, openCreation: false, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  edit = (row) => {
    this.setState({
      'bankName': row.bankName,
      'bankNumber': row.bankNumber,
      'chosen': row.Bankid
    });
  }

  reset = (row) => {
    this.setState({
      'chosen': '',
      'Bankid': '',
      'bankName': '',
      'bankNumber': '',
    });
  }

  logout = () => {
    window.localStorage.setItem('token', '');
    window.location.reload();
  }

  resetSearch = () => {
    this.setState({search: '', table: this.state.originalTable})
  }

  search = (value) => {
    this.setState({search: value}, () => {
      if (value === '') this.setState({table: this.state.originalTable});
      else {
        let table_filtered = [];
        this.state.originalTable.forEach((item, i) => {
          try {
            if (
              value.includes(item.bankName) ||
              value.includes(item.bankNumber)
            ) {
              table_filtered.push(item);
            }
          } catch (err) {
            console.log(err);
          }
        });
        this.setState({table: table_filtered});
      }
    });
  }

  render() {
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}


            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <br />

            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <h4 style={{marginBottom: 0}}><b>ניהול בנקים</b></h4>
            {this.state.openCreation ? (
              <Button color={'primary'} variant={'contained'} style={{marginTop: '-7%', marginLeft: '2%', float: 'left',}} onClick={(e) => this.setState({openCreation: false}, () => {this.reset()})}>הסתר הוספה</Button>
            ) : (
              <Button color={'primary'} variant={'contained'} style={{marginTop: '-3%', marginLeft: '2%', float: 'left',}} onClick={(e) => this.setState({openCreation: true}, () => {this.reset()})}>+ הוסף</Button>
            )}
            <div style={{textAlign: 'right', padding: '1%', marginTop: 0}}>
              <a href="" style={{color: 'red', textDecoration: 'underline'}} onClick={(e) => this.logout()}>התנתק</a>
            </div>
            <div style={{textAlign: 'left', padding: '1%', marginTop: '-2%'}}>
              <a href="/" style={{color: 'blue', textDecoration: 'underline'}}>חזרה לעמוד הבית</a>
            </div><br />
            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div>
                {this.state.openCreation && (
                  <div>
                  <h4>הוספת רשומה חדשה</h4>
                  <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          {Object.keys(headers).map((item) => {
                            return (
                              <TableCell align="right">{headers[item]}</TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={'1_creation'}>
                          <TableCell component="th" scope="row" align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.create()}>יצירה</Button></TableCell>
                          <TableCell component="th" scope="row" align="right"><TextField label={headers['bankName']} value={this.state.bankName} onChange={(e) => this.setState({bankName: e.target.value})}/></TableCell>
                          <TableCell component="th" scope="row" align="right"><TextField label={headers['bankNumber']} value={this.state.bankNumber} onChange={(e) => this.setState({bankNumber: e.target.value})}/></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  </div>
                )}

                <div>
                  <p>חיפוש על פי טקסט</p>

                  <Button onClick={(e) => this.resetSearch()}>איפוס</Button>
                  <TextField style={{width: 300}} value={this.state.search} onChange={(e) => this.search(e.target.value)} plcaeholder={'טקסט לחיפוש כאן..'} />
                </div>
                <TableContainer component={Paper} style={{maxWidth: '100%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        {Object.keys(headers).map((item) => {
                          return (
                            <TableCell align="right">{headers[item]}</TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.table.map((row) => {
                        if (this.state.chosen === row.Bankid) {
                          return (
                            <TableRow key={row.name}>
                              <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.delete(row)}>מחיקה</Button></TableCell>
                              <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.update(row)}>שמור</Button></TableCell>
                              <TableCell component="th" scope="row" align="right"><TextField label={headers['bankName']} value={this.state.bankName} onChange={(e) => this.setState({bankName: e.target.value})}/></TableCell>
                              <TableCell component="th" scope="row" align="right"><TextField label={headers['bankNumber']} value={this.state.bankNumber} onChange={(e) => this.setState({bankNumber: e.target.value})}/></TableCell>
                            </TableRow>
                          );
                        } else {
                          return (
                            <TableRow key={row.bankName}>
                              <TableCell TableCell align="right" component="th" scope="row"></TableCell>
                              <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'}><a style={{textDecoration: 'none', color: 'white'}} href={"/branches/"+row.bankName}>הצג סניפים</a></Button></TableCell>
                              <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.edit(row)}>ערוך</Button></TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.bankName}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.bankNumber}</TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Banks;
