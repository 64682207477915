import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Clients from './pages/clients/Clients';
import Home from './pages/Home';
import Swap from './pages/Swap';
import Full from './pages/Full';
import Login from './pages/Login';
import GroupValues from './pages/GroupValues';
import Cities from './pages/Cities';
import Banks from './pages/Banks';
import Branches from './pages/Branches';
import Items from './pages/Items';
import Colors from './pages/Colors';
import Logout from './pages/Logout';
import Vat from './pages/Vat';

import axios from 'axios';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      loading: false
    };
  }

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/validate', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({logged: true, loading: false});
      }).catch(err => {
        this.setState({logged: false, loading: false});
      });
    });
  }

  render() {
    return (
      <div>
      {this.state.logged ? (
        <Router>
          <Switch>
            <Route path="/vat">
              <Vat loading={this.state.loading}/>
            </Route>
            <Route path="/logout">
              <Logout loading={this.state.loading}/>
            </Route>
            <Route path="/full-table">
              <Full loading={this.state.loading}/>
            </Route>
            <Route path="/swap">
              <Swap loading={this.state.loading}/>
            </Route>
            <Route path="/colors">
              <Colors loading={this.state.loading}/>
            </Route>
            <Route path="/items">
              <Items loading={this.state.loading}/>
            </Route>
            <Route path="/branches">
              <Branches loading={this.state.loading}/>
            </Route>
            <Route path="/banks">
              <Banks loading={this.state.loading}/>
            </Route>
            <Route path="/cities">
              <Cities loading={this.state.loading}/>
            </Route>
            <Route path="/group-value">
              <GroupValues loading={this.state.loading}/>
            </Route>
            <Route path="/clients">
              <Clients loading={this.state.loading}/>
            </Route>
            <Route path="/">
              <Home loading={this.state.loading}/>
            </Route>
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path="/">
              <Login loading={this.state.loading}/>
            </Route>
          </Switch>
        </Router>
      )}
      </div>
    );
  }
}

export default App;
