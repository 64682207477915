import React, { Component } from 'react';
import '../../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from '../../components/Loading';
import Toast from '../../components/Toast';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContent from '../../components/DrawerContent';
import DateFnsUtils from '@date-io/date-fns';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const headers = {
  status: 'סטאטוס',
  paymentForm: 'צורת תשלום',
  paymentDay: 'יום בחודש',
  phoneNumber: 'מספר טלפון',
  lastName: 'שם משפחה',
  firstName: 'שם פרטי',
  id: 'קוד לקוח',
};

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      table: [],
      originalTable: [],
      openCreation: false,
      loading: false,
      Itemid: '',
      branchCode: '',
      items: [],
      branchName: '',
      branchAddress: '',
      branchCity: '',
      branchPostCode: '',
      deleteToast: false,
      toDate: new Date('3000-01-01'),
      sortBy: '',
      fromDate: '',
      openFromDate: false,
      notes: [],
      historyOpen: false,
      openRegCreation: false,
      tableCheck: 1,
      client: {},
      clientNotes: [],
      banks: [],
      branches: [],
      branchesChosen: [],
      clientTemplates: [],
      CustomClientID: '',
      officialID: '',
      VATPtor: 0,
      defaultPrint: false,
      templateIdEdit: '',
      isWithVAT: false,
      gender: 'זכר',
      cStatus: 'פעיל',
      groupingInvoices: 'חשבונית מרוכזת',
      paymentForm: 'סליקה',
      paymentDay: '1',
      status_search: '',
      paymentForm_search: '',
      paymentDay_search: '',
      phoneNumber_search: '',
      lastName_search: '',
      firstName_search: '',
      id_search: '',
      Clientid_search: '',

    };
  }

  componentDidMount() {
    this.getData();
    this.getBanks();
    this.getBranches();
  }

  getBanks = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/banks', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        let d = res.data.data;
        d.sort(function (a, b) {
          return parseInt(a.bankNumber) - parseInt(b.bankNumber);
        });
        this.setState({banks: d, loading: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  getBranches = (name) => {
    this.setState({loading: true,}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/branches', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        let data = res.data.data;
        this.setState({branches: data, loading: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  setBranches = (bankName) => {
    const branches = this.state.branches;
    let output = [];
    branches.forEach((item, i) => {
      if (item.bankName.toString() === bankName.toString() || item.bankName.includes(bankName)) output.push(item);
    });
    console.log(output.length)
    this.setState({branchesChosen: output});
  }

  getNotesByClient = () => {
    let Clientid = this.state.chosen;
    let notes = this.state.notes;
    let output = [];
    console.log(Clientid)
    notes.forEach((item, i) => {
      console.log(item);
      if (item.Clientid.toString() === Clientid.toString()) output.push(item);
    });
    console.log(output);
    this.setState({clientNotes: output});
  }

  getTemplatesByClient = () => {
    let Clientid = this.state.chosen;
    let templates = this.state.templates;
    let output = [];
    templates.forEach((item, i) => {
      console.log(item);
      if (item.Clientid.toString() === Clientid.toString()) output.push(item);
    });
    console.log(output);
    this.setState({clientTemplates: output});
  }

  setClient = (row) => {
    console.log(row);
    this.setState({chosen: row.Clientid, idNumber: row.idNumber, CustomClientID: row.Clientid, client: row, ...row}, () => {
      this.setBranches(this.state.bankName)
      this.getNotesByClient();
      this.getTemplatesByClient();
    });
  }

  groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  getData = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/cma/table', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        let data = res.data.data;
        let notes = res.data.data.notes;
        let templates = res.data.data.templates;

        this.setState({table: data.clients, notes: notes, templates: templates, originalTable: data.clients, loading: false}, () => {
          if (this.state.chosen !== '') {
            this.setBranches(this.state.bankName)
            this.getNotesByClient()
            this.getTemplatesByClient()
          }
          return axios.get('http://api.sivan-cma.com/v1/items', {
            headers: { Authorization: `Bearer ${token}` }
          }).then(res => {
            let data = res.data.data;
            data = this.groupBy(data, 'name')
            this.setState({items: data, loading: false});
          }).catch(err => {
            console.log(err);
            this.setState({loading: false})
          });
        });
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  createNote = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.post('http://api.sivan-cma.com/v1/cma/note', {
        Clientid: this.state.Clientid,
        note: this.state.note,
        createdAt: this.state.noteDate,
        defaultPrint: this.state.defaultPrint
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.getData();
        this.setState({note: '', noteDate: '', defaultPrint: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  createTemplate = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.post('http://api.sivan-cma.com/v1/cma/payment-templates', {
        Clientid: this.state.Clientid,
        templateName: this.state.templateName,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        paymentsNumber: this.state.paymentsNumber,
        product: this.state.product,
        currentPrice: this.state.currentPrice,
        isWithVAT: this.state.isWithVAT ? 1 : 0,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.getData();
        this.setState({note: '', noteDate: '',
        templateName: '',
  fromDate: '',
  toDate: '',
  paymentsNumber: '',
  product: '',
  currentPrice: '', isWithVAT: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  decideToDate = (toDate, fromDate, templateName, paymentsNumber) => {
    /*

      <MenuItem value={"חודשי קבוע"}>חודשי קבוע</MenuItem>
      <MenuItem value={"שנתי קבוע"}>שנתי קבוע</MenuItem>
    */
    if (templateName === "חד פעמי") return fromDate;
    else if (templateName === "חודשי קבוע" || templateName === "שנתי קבוע") return '01-01-3000';
    else if (templateName === "מספר תשלומים") {
      let date = new Date(Date.now());
      let newDate = new Date(date.setMonth(date.getMonth()+(parseInt(paymentsNumber) + 1)));
      return newDate;
    } else return toDate;
  }

  editTemplate = (id) => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/cma/payment-templates?id='+this.state.templateIdEdit, {
        templateName: this.state.edit_templateName,
        fromDate: this.state.edit_fromDate,
        toDate: this.decideToDate(this.state.edit_toDate, this.state.edit_fromDate, this.state.edit_templateName, this.state.edit_paymentsNumber),
        paymentsNumber: this.state.edit_paymentsNumber,
        product: this.state.edit_product,
        currentPrice: this.state.edit_currentPrice,
        isWithVAT: this.state.edit_isWithVAT ? 1 : 0,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({loading: false, edit_toDate: '', edit_product: '', edit_fromDate: '', edit_currentPrice: '', edit_isWithVAT: false, edit_templateName: '', edit_paymentsNumber: '', templateIdEdit: ''})
        this.getData();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  removeNote = (Noteid) => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/cma/note?id='+Noteid, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.getData();
        this.setState({note: '', noteDate: ''});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  removeTemplate = (Templateid) => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/cma/payment-templates?id='+Templateid, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.getData();
        this.setState({note: '', noteDate: ''});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  removeClient = (Clientid) => {
    if (window.confirm("האם אתה בטוח שברצונך למחוק לקוח זה?")) {
      this.setState({loading: true}, () => {
        const token = window.localStorage.getItem('token');
        return axios.delete('http://api.sivan-cma.com/v1/cma/client?id='+Clientid, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
          this.getData();
          this.setState({chosen: ''})
        }).catch(err => {
          console.log(err);
          this.setState({loading: false})
        });
      });
    }
  }

  validateIDNumber = (id) => {
    	id = String(id).trim();
    	if (id.length > 9 || id.length < 5 || isNaN(id)) return false;
    	id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    	return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
	    }) % 10 === 0;
  }

  createClient = () => {
    if (this.validateIDNumber(this.state.idNumber) || this.state.idNumber === '') {
      const regExp = /[a-zA-Z]/g;
      const regExpB = /[a-zA-Z.]/g;

      if (this.state.firstName === "" || this.state.lastName === "") alert('חובה למלא שם מלא!');
      else if (regExp.test(this.state.faxNumber)) {
        alert('מספר פקס חייב להיות אך ורק מספרים')
      }
      else if (regExp.test(this.state.phoneNumber)) {
        alert('מספר טלפון חייב להיות אך ורק מספרים')
      }
      else if (regExpB.test(this.state.maximumAmountCharge)) {
        alert('מספר מקסימלי לחיוב חייב להיות רק מספרים')
      }
      else if (regExp.test(this.state.officialID)) {
        alert('מספר עוסק או ח.פ חייב להיות רק מספרים')
      }
      else if (regExp.test(this.state.idNumber)) {
        alert('מספר תעודת זהות חייב להיות רק מספרים')
      }
      else {
        let payload = {
          accountantCodes: this.state.accountantCodes,
          cStatus: this.state.cStatus,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          city: this.state.city,
          address: this.state.address,
          postcode: this.state.postcode,
          maximumAmountCharge: (parseFloat(this.state.maximumAmountCharge).toFixed(2)).toString(),
          gender: this.state.gender,
          phoneNumber: this.state.phoneNumber === undefined ? '' : this.state.phoneNumber,
          faxNumber: this.state.faxNumber === undefined ? '' : this.state.faxNumber,
          email: this.state.email,
          paymentForm: '',
          groupingInvoices: '',
          receivingInvoices: 0,
          sendInvoicesEmail: 0,
          bankName: '',
          bankBranchNumber: '',
          bankAccountNumber: '',
          paymentDay: '',
          cName: '',
          idNumber: (parseInt(this.state.idNumber)).toString(),
          cga: '',
          officialID: (parseInt(this.state.officialID)).toString(),
          VATPtor: this.state.VATPtor,
          cgaDM: (new Date(Date.now()).getMonth() + 1).toString(),
          cgaDY: new Date(Date.now()).getFullYear(),
          createdAt: new Date(Date.now()),
        };
        console.log(payload)
        this.setState({loading: true}, () => {
          const token = window.localStorage.getItem('token');
          return axios.post('http://api.sivan-cma.com/v1/cma/client', payload, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(res => {
            window.location.reload();
          }).catch(err => {
            console.log(err);
            this.setState({loading: false})
          });
        });
      }
    } else {
      alert('מספר תעודת זהות אינו תקין');
    }
  }

  updateClient = () => {
    const regExp = /[a-zA-Z]/g;
    const regExpB = /[a-zA-Z.]/g;

    if (this.state.firstName === "" || this.state.lastName === "") alert('חובה למלא שם מלא!');
    else if (regExp.test(this.state.faxNumber)) {
      alert('מספר פקס חייב להיות אך ורק מספרים')
    }
    else if (regExp.test(this.state.phoneNumber)) {
      alert('מספר טלפון חייב להיות אך ורק מספרים')
    }
    else if (regExpB.test(this.state.maximumAmountCharge)) {
      alert('מספר מקסימלי לחיוב חייב להיות רק מספרים')
    }
    else if (regExpB.test(this.state.bankAccountNumber)) {
      alert('מספר חשבון בנק חייב להיות רק מספרים')
    }
    else if (regExp.test(this.state.officialID)) {
      alert('מספר עוסק או ח.פ חייב להיות רק מספרים')
    }
    else if (regExp.test(this.state.idNumber)) {
      alert('מספר תעודת זהות חייב להיות רק מספרים')
    }
    else {
      let payload = {
        accountantCodes: this.state.accountantCodes,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        city: this.state.city,
        address: this.state.address,
        postcode: this.state.postcode,
        maximumAmountCharge: (parseFloat(this.state.maximumAmountCharge).toFixed(2)).toString(),
        gender: this.state.gender,
        phoneNumber: this.state.phoneNumber,
        faxNumber: this.state.faxNumber,
        email: this.state.email,
        paymentForm: this.state.paymentForm,
        groupingInvoices: this.state.groupingInvoices,
        receivingInvoices: this.state.receivingInvoices,
        sendInvoicesEmail: this.state.sendInvoicesEmail,
        bankName: this.state.bankName,
        bankBranchNumber: this.state.bankBranchNumber,
        bankAccountNumber: (parseInt(this.state.bankAccountNumber)).toString(),
        paymentDay: this.state.paymentDay,
        cName: this.state.cName,
        idNumber: (parseInt(this.state.idNumber)).toString(),
        cga: this.state.cga,
        cgaDM: this.state.cgaDM,
        cgaDY: this.state.cgaDY,
        cStatus: this.state.cStatus,
        createdAt: this.state.createdAt,
        officialID: (parseInt(this.state.officialID)).toString(),
        VATPtor: this.state.VATPtor,
        CustomClientID: this.state.CustomClientID
      };
      console.log(payload)
      this.setState({loading: true}, () => {
        const token = window.localStorage.getItem('token');
        return axios.put('http://api.sivan-cma.com/v1/cma/client?id='+this.state.Clientid, payload, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
          this.getData();
          window.location.reload()
        }).catch(err => {
          console.log(err);
          this.setState({loading: false})
        });
      });
    }
  }

  edit = (id, name) => {
    this.setState({
      'name': name,
      'chosen': id
    });
  }

  reset = (row) => {
    this.setState({
      'chosen': '',
      'Itemid': '',
      'name': '',
      'amountWithVat': '',
      'fromDate': '',
      'toDate': '01-01-3000',
      'chosen': '',
      'Itemid': '',
      'branchCode': '',
      'branchName': '',
      'branchAddress': '',
      'branchCity': '',
      'branchPostCode': '',
      'fromDate': '',
      'CustomClientID': '',
      'firstName': '',
      'lastName': '',
      'groupingInvoices': '',
      'receivingInvoices': '',
      'sendInvoicesEmail': '',
      'email': '',
      'postcode': '',
      'city': '',
      'accountantCodes': '',
      'address': '',
      'VATPtor': 0,
      'officialID': ''
    });
  }

  resetSearch = () => {
    this.setState({search: '', table: this.state.originalTable})
  }

  search = (value) => {
    this.setState({search: value}, () => {
      if (value === '') this.setState({table: this.state.originalTable});
      else {
        let table_filtered = [];
        this.state.originalTable.forEach((item, i) => {
          try {
            if (
              value.includes(item.name) ||
              value.includes(item.amountWithVat) ||
              value.includes(item.fromDate) ||
              value.includes(item.toDate)
            ) {
              table_filtered.push(item);
            }
          } catch (err) {
            console.log(err);
          }
        });
        this.setState({table: table_filtered});
      }
    });
  }

  defaultValue = (df) => {
    if (df && df !== null && df !== undefined) return df;
    return '';
  }

  searchClients = () => {
      const table = this.state.originalTable;
      if (
        this.state.status_search === '' &&
        this.state.paymentForm_search === '' &&
        this.state.paymentDay_search === '' &&
        this.state.phoneNumber_search === '' &&
        this.state.lastName_search === '' &&
        this.state.firstName_search === '' &&
        this.state.Clientid_search === ''
      ) {
        this.setState({table: table})
      } else {
      let table_results = [];
        table.forEach((item, i) => {
          console.log(item.Clientid);
          if (
            (this.state.Clientid_search !== '' && (this.state.Clientid_search === item.Clientid || this.defaultValue(item.Clientid).toString().includes(this.state.Clientid_search))) ||
            (this.state.status_search !== '' && (this.state.status_search === item.status || this.defaultValue(item.status).includes(this.state.status_search))) ||
            (this.state.paymentForm_search !== '' && (this.state.paymentForm_search === item.paymentForm || this.defaultValue(item.paymentForm).includes(this.state.paymentForm_search))) ||
            (this.state.paymentDay_search !== '' && (this.state.paymentDay_search === item.paymentDay || this.defaultValue(item.paymentDay).includes(this.state.paymentDay_search))) ||
            (this.state.phoneNumber_search !== '' && (this.state.phoneNumber_search === item.phoneNumber || this.defaultValue(item.phoneNumber).includes(this.state.phoneNumber_search))) ||
            (this.state.lastName_search !== '' && (this.state.lastName_search === item.lastName || this.defaultValue(item.lastName).includes(this.state.lastName_search))) ||
            (this.state.firstName_search !== '' && (this.state.firstName_search === item.firstName || this.defaultValue(item.firstName).includes(this.state.firstName_search)))
          ) {
            console.log(item);
            table_results.push(item);
          }
        });
        this.setState({table: table_results}, () => {
          console.log('table_results:', table_results);
        })
      }
  }

  render() {
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card_clients">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}


            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <br />

            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <h4 style={{marginBottom: 0}}><b>ניהול לקוחות</b></h4>

            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div>
              {this.state.openCreation ? (
                <div>
                <h4>הוספת רשומה חדשה</h4>
                <div>
                  <div>
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'קוד הנהלת חשבונות'} value={this.state.accountantCodes} onChange={(e) => this.setState({accountantCodes: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'שם פרטי'} value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'שם משפחה'} value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})}/> <br /><br />
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'עיר'} value={this.state.city} onChange={(e) => this.setState({city: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'כתובת'} value={this.state.address} onChange={(e) => this.setState({address: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מיקוד'} value={this.state.postcode} onChange={(e) => this.setState({postcode: e.target.value})}/> <br /><br />
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'סכום מקסימלי לחיוב'} value={this.state.maximumAmountCharge} onChange={(e) => this.setState({maximumAmountCharge: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מספר טלפון'} value={this.state.phoneNumber} onChange={(e) => this.setState({phoneNumber: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מספר פקס'} value={this.state.faxNumber} onChange={(e) => this.setState({faxNumber: e.target.value})}/> <br /><br />
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'אימייל'} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/> &nbsp;&nbsp;
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'ח.פ/ע.מ'} value={this.state.officialID} onChange={(e) => this.setState({officialID: e.target.value})}/>
                  <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'ת.ז לקוח'} value={this.state.idNumber} onChange={(e) => this.setState({idNumber: e.target.value})}/>
                  <br />
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div style={{marginRight: '1%', marginLeft: '1%'}}>
                    <p style={{direction: 'rtl'}}>סטאטוס:</p>
                    <Select onChange={(e) => this.setState({cStatus: e.target.value})} value={this.state.cStatus === '' ? 'פעיל' : this.state.cStatus} InputLabelProps={{ shrink: true }} label={'סטאטוס'}>
                      <MenuItem value={'פעיל'}>פעיל</MenuItem>
                      <MenuItem value={'לא פעיל'}>לא פעיל</MenuItem>
                      <MenuItem value={'מוקפא'}>מוקפא</MenuItem>
                      <MenuItem value={'עזב'}>עזב</MenuItem>
                      <MenuItem value={'הפסיק לעבוד'}>הפסיק לעבוד</MenuItem>
                    </Select>
                    </div>
                    <div style={{marginRight: '1%', marginLeft: '1%'}}>
                    <p style={{direction: 'rtl'}}>מגדר:</p>
                    <Select onChange={(e) => this.setState({gender: e.target.value})} value={this.state.gender === '' ? 'זכר' : this.state.gender} InputLabelProps={{ shrink: true }} label={'סטאטוס'}>
                      <MenuItem value="זכר">זכר</MenuItem>
                      <MenuItem value="נקבה">נקבה</MenuItem>
                      <MenuItem value="חברה">חברה</MenuItem>
                    </Select>
                    </div>
                  </div>
                   <br /><br />
                  <Button color={'primary'} variant={'contained'} onClick={(e) => this.createClient()}>יצירה</Button>
                  </div>
                </div>
                <br />
                </div>
              ) : (
                <div>
                <Button onClick={(e) => this.setState({openCreation: true}, () => { this.reset() })} style={{direction: 'rtl', marginTop: '1%', marginBottom: '1%'}} color={'primary'} variant={'contained'}>הוספת לקוח +</Button>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div style={{width: '100%', minHeight: '65vh', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'}}>
                    <div style={{display: 'fixed', backgroundColor: 'white', border: '1px solid #add8e6'}}>
                      <ButtonGroup color="primary" aria-label="outlined primary button group" style={{width: '100%'}}>
                        <Button onClick={(e) => this.setState({tableCheck: 5})} style={{width: '33%', backgroundColor: this.state.tableCheck === 5 ? '#3f51b5' : '', color: this.state.tableCheck === 5 ? 'white' : ''}}>היסטוריית חיובים</Button>
                        <Button onClick={(e) => this.setState({tableCheck: 4})} style={{width: '33%', backgroundColor: this.state.tableCheck === 4 ? '#3f51b5' : '', color: this.state.tableCheck === 4 ? 'white' : ''}}>תבניות חיוב</Button>
                        <Button onClick={(e) => this.setState({tableCheck: 3})} style={{width: '33%', backgroundColor: this.state.tableCheck === 3 ? '#3f51b5' : '', color: this.state.tableCheck === 3 ? 'white' : ''}}>הערות</Button>
                        <Button onClick={(e) => this.setState({tableCheck: 2})} style={{width: '33%', backgroundColor: this.state.tableCheck === 2 ? '#3f51b5' : '', color: this.state.tableCheck === 2 ? 'white' : ''}}>אמצעי תשלום</Button>
                        <Button onClick={(e) => this.setState({tableCheck: 1})} style={{width: '33%', backgroundColor: this.state.tableCheck === 1 ? '#3f51b5' : '', color: this.state.tableCheck === 1 ? 'white' : ''}}>פרטים כלליים</Button>
                      </ButtonGroup>
                    </div>

                    {this.state.chosen === '' ? (
                      <h1>לחץ על לקוח על מנת לראות את הנתונים שלהם</h1>
                    ) : (
                      <div>
                        <p style={{textAlign: 'right', marginRight: '1%'}}><b>אתה צופה בלקוח: {this.state.firstName + ' ' + this.state.lastName}</b></p>
                        {this.state.tableCheck === 1 && (
                          <div style={{backgroundColor: 'white', padding: '1%', marginTop: '5%'}}>

                         <Button color={'primary'} variant={'contained'} onClick={(e) => this.updateClient()}>עדכון</Button>
                         <br/><br/>
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'קוד הנהלת חשבונות'} value={this.state.accountantCodes} onChange={(e) => this.setState({accountantCodes: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'שם פרטי'} value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'שם משפחה'} value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})}/> <br /><br />
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'עיר'} value={this.state.city} onChange={(e) => this.setState({city: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'כתובת'} value={this.state.address} onChange={(e) => this.setState({address: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מיקוד'} value={this.state.postcode} onChange={(e) => this.setState({postcode: e.target.value})}/> <br /><br />
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'סכום מקסימלי לחיוב'} value={this.state.maximumAmountCharge} onChange={(e) => this.setState({maximumAmountCharge: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מספר טלפון'} value={this.state.phoneNumber} onChange={(e) => this.setState({phoneNumber: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מספר פקס'} value={this.state.faxNumber} onChange={(e) => this.setState({faxNumber: e.target.value})}/> <br /><br />
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'אימייל'} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'קוד לקוח'} value={(this.state.CustomClientID === null || this.state.CustomClientID === 'null') ? '' : this.state.CustomClientID} onChange={(e) => this.setState({CustomClientID: e.target.value})}/> &nbsp;&nbsp;
                            <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'ח.פ/ע.מ'} value={this.state.officialID} onChange={(e) => this.setState({officialID: e.target.value})}/>

                            <br />
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                              <p style={{direction: 'rtl'}}>סטאטוס:</p>
                              <Select onChange={(e) => this.setState({cStatus: e.target.value})} value={this.state.cStatus === '' ? 'פעיל' : this.state.cStatus} InputLabelProps={{ shrink: true }} label={'סטאטוס'}>
                                <MenuItem value={'פעיל'}>פעיל</MenuItem>
                                <MenuItem value={'לא פעיל'}>לא פעיל</MenuItem>
                                <MenuItem value={'מוקפא'}>מוקפא</MenuItem>
                                <MenuItem value={'עזב'}>עזב</MenuItem>
                                <MenuItem value={'הפסיק לעבוד'}>הפסיק לעבוד</MenuItem>
                              </Select>
                              </div>
                              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                              <p style={{direction: 'rtl'}}>מגדר:</p>
                              <Select onChange={(e) => this.setState({gender: e.target.value})} value={this.state.gender === '' ? 'זכר' : this.state.gender} InputLabelProps={{ shrink: true }} label={'סטאטוס'}>
                                <MenuItem value="זכר">זכר</MenuItem>
                                <MenuItem value="נקבה">נקבה</MenuItem>
                                <MenuItem value="חברה">חברה</MenuItem>
                              </Select>
                              </div>
                            </div>
                             <br /><br />
                            <Button color={'primary'} variant={'contained'} onClick={(e) => this.updateClient()}>עדכון</Button>
                            <br />
                            <br />
                            <br />
                            <Button color={'secondary'} variant={'contained'} onClick={(e) => this.removeClient(this.state.chosen)} className="tfield">מחק לקוח!</Button>
                          </div>
                        )}
                        {this.state.tableCheck === 2 && (
                          <div style={{backgroundColor: 'white', padding: '1%', marginTop: '5%'}}>
                            <Button color={'primary'} variant={'contained'} onClick={(e) => this.updateClient()}>עדכון</Button>
                            <div className="tablecheck_form">
                              <p style={{textDecoration: 'underline', fontWeight: 'bold'}}>הגדרות כלליות</p>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                              {/*<TextField className="tfield" InputLabelProps={{ shrink: true }} label={'צורת תשלום'} value={this.state.paymentForm} onChange={(e) => this.setState({paymentForm: e.target.value})}/> &nbsp;&nbsp;*/}
                              {/*<TextField className="tfield" InputLabelProps={{ shrink: true }} label={'קיבוץ חשבוניות'} value={this.state.groupingInvoices} onChange={(e) => this.setState({groupingInvoices: e.target.value})}/> <br />*/}
                              <div style={{}}>
                              <span style={{direction: 'rtl', marginTop: '-2%'}}>:צורת תשלום</span><br />
                              <Select className="tfield" value={this.state.paymentForm} onChange={(e) => this.setState({paymentForm: e.target.value})}>
                                {
                                  [
                                    'הוראת קבע',
                                    'סליקה',
                                    'אשראי',
                                    'אפליקציה',
                                    'שיק',
                                    'מזומן',
                                    'חשבונית בלבד',
                                  ].map((item) => {
                                    return (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                }
                              </Select>
                              </div>
                              &nbsp;&nbsp;
                              <div style={{}}>
                              <span style={{direction: 'rtl', marginTop: '-2%'}}>:קיבוץ חשבוניות</span><br />
                              <Select className="tfield" value={this.state.groupingInvoices} onChange={(e) => this.setState({groupingInvoices: e.target.value})}>
                                {
                                  [
                                    'חשבונית מרוכזת',
                                    'חשבונית לכל פריט'
                                  ].map((item) => {
                                    return (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                }
                              </Select>
                              </div>
                              </div>
                              <div style={{marginRight: '1%', marginLeft: '1%'}}>
                              <p style={{direction: 'rtl'}}>יום חיוב בחודש:</p>
                                <Select className="tfield" value={this.state.paymentDay} onChange={(e) => this.setState({paymentDay: e.target.value})}>
                                  {
                                    [
                                      '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17',
                                      '18','19','20','21','22','23','24','25','26','27','28'
                                    ].map((item) => {
                                      return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      );
                                    })
                                  }
                                </Select>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <div style={{marginRight: '1%', marginLeft: '1%'}}><Checkbox checked={this.state.receivingInvoices === 1} onClick={(e) => this.setState({receivingInvoices: this.state.receivingInvoices === 1 ? 0 : 1})}/> חשבונית</div>
                                <div style={{marginRight: '1%', marginLeft: '1%'}}><Checkbox checked={this.state.sendInvoicesEmail === 1} onClick={(e) => this.setState({sendInvoicesEmail: this.state.sendInvoicesEmail === 1 ? 0 : 1})}/> מייל</div>
                                <div style={{marginRight: '1%', marginLeft: '1%'}}><Checkbox checked={this.state.VATPtor === 1} onClick={(e) => this.setState({VATPtor: this.state.VATPtor === 1 ? 0 : 1})}/> מע״מ</div>
                              </div>
                            </div>
                            <div className="tablecheck_form">
                              <p style={{textDecoration: 'underline', fontWeight: 'bold'}}>פרטי חשבון בנק</p>
                              <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מספר חשבון'} value={this.state.bankAccountNumber} onChange={(e) => this.setState({bankAccountNumber: e.target.value})}/> &nbsp;&nbsp;
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>


                                <div style={{marginRight: '1%', marginLeft: '1%'}}>
                                <p style={{direction: 'rtl'}}>סניף בנק:</p>
                                  <Select className="tfield" value={this.state.bankBranchNumber} onChange={(e) => this.setState({bankBranchNumber: e.target.value})}>
                                    {this.state.branchesChosen.map((branch) => {
                                      return (
                                        <MenuItem value={branch.branchCode}>{branch.branchCode}</MenuItem>
                                      )
                                    })}
                                  </Select>
                                </div>
                                <div style={{marginRight: '1%', marginLeft: '1%'}}>
                                <p style={{direction: 'rtl'}}>בנק:</p>
                                  <Select className="tfield" value={this.state.bankName} onChange={(e) => this.setState({bankName: e.target.value}, () => { this.setBranches(this.state.bankName) })}>
                                    {this.state.banks.map((bank) => {
                                      console.log(bank);
                                      return (
                                        <MenuItem value={bank.bankName}>{bank.bankName} ({bank.bankNumber})</MenuItem>
                                      )
                                    })}
                                  </Select>
                                </div>
                              </div>
                            </div>
                            <div className="tablecheck_form">
                              <p style={{textDecoration: 'underline', fontWeight: 'bold'}}>פרטי כרטיס אשראי</p>
                              <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'בעל הכרטיס'} value={this.state.cName} onChange={(e) => this.setState({cName: e.target.value})}/> &nbsp;&nbsp;
                              <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'תעודת זהות'} value={this.state.idNumber} onChange={(e) => this.setState({idNumber: e.target.value})}/> <br />
                              <TextField className="tfield" InputLabelProps={{ shrink: true }} label={'מספר כרטיס'} value={this.state.cga} onChange={(e) => this.setState({cga: e.target.value})}/> &nbsp;&nbsp;
                              <p>תוקף</p>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <div style={{marginRight: '1%', marginLeft: '1%'}}>
                                <p style={{direction: 'rtl'}}>שנה</p>
                                  <Select onChange={(e) => this.setState({cgaDY: e.target.value})} className="tfield" value={this.state.cgaDY}>
                                    {
                                      [
                                        '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029',
                                        '2030', '2031'
                                      ].map((item) => {
                                        return (
                                          <MenuItem value={item}>{item}</MenuItem>
                                        );
                                      })
                                    }
                                  </Select>
                                </div>
                                <div style={{marginRight: '1%', marginLeft: '1%'}}>
                                <p style={{direction: 'rtl'}}>חודש</p>
                                <Select onChange={(e) => this.setState({cgaDM: e.target.value})} className="tfield" value={this.state.cgaDM}>
                                  {
                                    [
                                      '01','02','03','04','05','06','07','08','09','10','11','12'
                                    ].map((item) => {
                                      return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      );
                                    })
                                  }
                                  </Select>
                                </div>
                              </div>
                            </div>
                            <br />
                            <Button color={'primary'} variant={'contained'} onClick={(e) => this.updateClient()}>עדכון</Button>
                          </div>
                        )}

                        {this.state.tableCheck === 3 && (
                          <div style={{marginRight: '2%'}}>
                            <table className="tfield table_r">
                              <tr className="tr_r">
                                <th className="th_r">הערה</th>
                                <th className="th_r">עד תאריך</th>
                                <th className="th_r">יודפס </th>
                                <th className="th_r">מחיקה</th>
                              </tr>
                              {this.state.clientNotes.map((item) => {
                                return (
                                  <tr className="tr_r">
                                    <td className="th_r" style={{width: '100%'}}>{item.note}</td>
                                    <td className="th_r" style={{width: '100%'}}>{new Date(item.createdAt).getDate() + '/' + (new Date(item.createdAt).getMonth() + 1).toString() + '/' + new Date(item.createdAt).getFullYear()}</td>
                                    <td className="th_r" style={{width: '100%'}}><Checkbox checked={item.defaultPrint} /></td>
                                    <td className="th_r" style={{width: '100%'}}><Button color={'secondary'} variant={'primary'} onClick={(e) => this.removeNote(item.Noteid)}>מחק</Button></td>
                                  </tr>
                                )
                              })}
                              <tr>
                                <td><textarea style={{width: '95%'}} value={this.state.note} onChange={(e) => this.setState({note: e.target.value})} className="tfield"></textarea></td>
                                <td><TextField style={{width: '95%'}} type="date" value={this.state.noteDate} onChange={(e) => this.setState({noteDate: e.target.value})} className="tfield" /></td>
                                <td><Checkbox style={{width: '95%'}} checked={this.state.defaultPrint} onChange={(e) => this.setState({defaultPrint: this.state.defaultPrint ? false : true})}/></td>
                                <td><Button style={{backgroundColor: 'green', border: '1px solid green', color: 'white', fontWeight:'bold'}} onClick={(e) => this.createNote()}> שמור </Button></td>
                              </tr>
                            </table>
                          </div>
                        )}

                        {this.state.tableCheck === 4 && (
                          <div style={{marginRight: '3%',}}>
                            <table className="tfield table_r" style={{width: '95%'}}>
                              <tr className="tr_r">
                              <th className="th_r">תבנית חיוב</th>
                              <th className="th_r">מתאריך</th>
                              <th className="th_r">עד תאריך</th>
                              <th className="th_r">מס' תשלומים</th>
                              <th className="th_r">מוצר</th>
                              <th className="th_r">כולל מע״מ</th>
                              <th className="th_r">מחיר נוכחי</th>
                              </tr>
                              {this.state.clientTemplates.map((item) => {
                                if (this.state.templateIdEdit === item.Templateid) {
                                  return (
                                    <tr className="tr_r">
                                      <td>
                                        {/*<TextField value={this.state.templateName} onChange={(e) => this.setState({templateName: e.target.value})} className="tfield" />*/}
                                        <Select value={this.state.edit_templateName} onChange={(e) => this.setState({edit_templateName: e.target.value}, () => {
                                          console.log(e.target.value);
                                        })}>
                                          <MenuItem value={"חודשי קבוע"}>חודשי קבוע</MenuItem>
                                          <MenuItem value={"שנתי קבוע"}>שנתי קבוע</MenuItem>
                                          <MenuItem value={"מספר תשלומים"}>מספר תשלומים</MenuItem>
                                          <MenuItem value={"חד פעמי"}>חד פעמי</MenuItem>
                                        </Select>&nbsp;
                                      </td>
                                      <td><TextField type="date" value={this.state.edit_fromDate} onChange={(e) => this.setState({edit_fromDate: e.target.value})} className="tfield" />&nbsp;</td>
                                      {(this.state.edit_templateName === "חודש קבוע" || this.state.edit_templateName === "שנתי קבוע") ? (
                                        <td>{this.state.edit_templateName === "חד פעמי" ? (<span>{this.state.edit_fromDate}</span>) : (<span></span>)}</td>
                                      ) : (
                                        <td><TextField type="date" value={this.state.edit_toDate} onChange={(e) => this.setState({edit_toDate: e.target.value})} className="tfield" />&nbsp;</td>
                                      )}
                                      <td><TextField value={this.state.edit_paymentsNumber} onChange={(e) => this.setState({edit_paymentsNumber: e.target.value})} className="tfield" />&nbsp;</td>
                                      {/*<td><TextField value={this.state.product} onChange={(e) => this.setState({product: e.target.value})} className="tfield" />&nbsp;</td>*/}
                                      <td>
                                        <Select value={this.state.edit_product} onChange={(e) => this.setState({edit_product: e.target.value.split('_')[0], edit_currentPrice: e.target.value.split('_')[1]}, () => {
                                          console.log(e.target.value);
                                        })}>
                                          {this.state.items && Object.keys(this.state.items).map((itemName, i) => {
                                            return (
                                              <MenuItem style={{direction:'ltr'}} value={itemName+'_'+this.state.items[itemName][0]['amountWithVat']}>{itemName}</MenuItem>
                                            )
                                          })}
                                        </Select>
                                      </td>
                                      <td><Checkbox onChange={(e) => this.setState({edit_isWithVAT: this.state.edit_isWithVAT ? false : true})} checked={this.state.edit_isWithVAT === undefined ? false : this.state.edit_isWithVAT} />&nbsp;</td>
                                      <td><TextField value={this.state.edit_currentPrice} onChange={(e) => this.setState({edit_currentPrice: e.target.value})} className="tfield" />&nbsp;</td>
                                      <td><Button onClick={(e) => this.editTemplate(item.Templateid)}> שמור </Button></td>
                                    </tr>
                                  )
                                } else {
                                  return (
                                    <tr className="tr_r">
                                      <td className="th_r">{(item.templateName === undefined || item.templateName === null || item.templateName === 'undefined')? '' : item.templateName}</td>
                                      <td className="th_r">{(item.fromDate === undefined || item.fromDate === '' || item.fromDate === null) ? '' : new Date(item.fromDate).getDate() + '/' + (new Date(item.fromDate).getMonth() + 1).toString() + '/' + new Date(item.fromDate).getFullYear()}</td>
                                      <td className="th_r">{(item.toDate === undefined || item.toDate === '' || item.toDate === null || new Date(item.toDate).getFullYear() === "3000" || new Date(item.toDate).getFullYear() === 3000) ? 'אין סוף' : new Date(item.toDate).getDate() + '/' + (new Date(item.toDate).getMonth() + 1).toString() + '/' + new Date(item.toDate).getFullYear()}</td>
                                      <td className="th_r">{(item.paymentsNumber === 'undefined' || item.paymentsNumber <= 0 || item.paymentsNumber === undefined || item.paymentsNumber === null) ? '1' : item.paymentsNumber}</td>
                                      <td className="th_r" style={{direction: 'ltr'}}>{item.product === undefined ? '' : item.product}</td>
                                      <td className="th_r"><Checkbox checked={item.isWithVAT === undefined ? false : item.isWithVAT} /></td>
                                      <td className="th_r">{item.currentPrice === undefined ? '' : item.currentPrice}</td>
                                      <td className="th_r"><Button style={{backgroundColor: 'red', border: '1px solid red', color: 'white', fontWeight: 'bold'}} variant={'primary'} onClick={(e) => this.removeTemplate(item.Templateid)}>מחק</Button></td>
                                      <td className="th_r"><Button style={{backgroundColor: 'green', border: '1px solid green', color: 'white', fontWeight: 'bold'}} variant={'primary'} onClick={(e) => this.setState({
                                        templateIdEdit: item.Templateid,
                                        edit_toDate: item.toDate,
                                        edit_product: item.product,
                                        edit_fromDate: item.fromDate,
                                        edit_isWithVAT: item.isWithVAT === 0 ? false : true,
                                        edit_currentPrice: item.currentPrice,
                                        edit_templateName: item.templateName,
                                        edit_paymentsNumber: item.paymentsNumber
                                      })}>ערוך</Button></td>
                                    </tr>
                                  )
                                }

                              })}
                              </table>
                              <br /><br />
                              <table className="tfield table_r" style={{width: '95%'}}>
                              <tr className="tr_r">
                                <th className="th_r">תבנית חיוב</th>
                                <th className="th_r">מתאריך</th>
                                <th className="th_r">עד תאריך</th>
                                <th className="th_r">מס' תשלומים</th>
                                <th className="th_r">מוצר</th>
                                <th className="th_r">כולל מע״מ</th>
                                <th className="th_r">מחיר נוכחי</th>
                              </tr>
                              <tr>
                                <td>
                                  {/*<TextField value={this.state.templateName} onChange={(e) => this.setState({templateName: e.target.value})} className="tfield" />*/}
                                  <Select value={this.state.templateName} onChange={(e) => this.setState({templateName: e.target.value}, () => {
                                    console.log(e.target.value);
                                  })}>
                                    <MenuItem value={"חודשי קבוע"}>חודשי קבוע</MenuItem>
                                    <MenuItem value={"שנתי קבוע"}>שנתי קבוע</MenuItem>
                                    <MenuItem value={"מספר תשלומים"}>מספר תשלומים</MenuItem>
                                    <MenuItem value={"חד פעמים"}>חד פעמי</MenuItem>
                                  </Select>&nbsp;
                                </td>
                                <td><TextField type="date" value={this.state.fromDate} onChange={(e) => this.setState({fromDate: e.target.value})} className="tfield" />&nbsp;</td>
                                <td><TextField type="date" value={this.state.toDate} onChange={(e) => this.setState({toDate: e.target.value})} className="tfield" />&nbsp;</td>
                                <td><TextField value={this.state.paymentsNumber} onChange={(e) => this.setState({paymentsNumber: e.target.value})} className="tfield" />&nbsp;</td>
                                {/*<td><TextField value={this.state.product} onChange={(e) => this.setState({product: e.target.value})} className="tfield" />&nbsp;</td>*/}
                                <td>
                                  <Select value={this.state.product} onChange={(e) => this.setState({product: e.target.value.split('_')[0], currentPrice: e.target.value.split('_')[1]}, () => {
                                    console.log(e.target.value);
                                  })}>
                                    {this.state.items && Object.keys(this.state.items).map((itemName, i) => {
                                      return (
                                        <MenuItem style={{direction:'ltr'}} value={itemName+'_'+this.state.items[itemName][0]['amountWithVat']}>{itemName}</MenuItem>
                                      )
                                    })}
                                  </Select>
                                </td>
                                <td><Checkbox checked={this.state.isWithVAT} onChange={(e) => this.setState({isWithVAT: this.state.isWithVAT ? false : true})}/>&nbsp;</td>
                                <td><TextField value={this.state.currentPrice} onChange={(e) => this.setState({currentPrice: e.target.value})} className="tfield" />&nbsp;</td>
                                <td><Button onClick={(e) => this.createTemplate()}> שמור </Button></td>
                              </tr>
                            </table>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                  <TableContainer component={Paper} style={{maxWidth: '100%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                            <TableCell align="right">
                              סטאטוס
                              <TextField value={this.state.status_search} onChange={(e) => this.setState({status_search: e.target.value}, () => { this.searchClients() })}/>
                            </TableCell>
                            <TableCell align="right">
                              צורת תשלום
                              <TextField value={this.state.paymentForm_search} onChange={(e) => this.setState({paymentForm_search: e.target.value}, () => { this.searchClients() })}/>
                            </TableCell>
                            <TableCell align="right">
                              יום בחודש
                              <TextField value={this.state.paymentDay_search} onChange={(e) => this.setState({paymentDay_search: e.target.value}, () => { this.searchClients() })}/>
                            </TableCell>
                            <TableCell align="right">
                              מספר טלפון
                              <TextField value={this.state.phoneNumber_search} onChange={(e) => this.setState({phoneNumber_search: e.target.value}, () => { this.searchClients() })}/>
                            </TableCell>
                            <TableCell align="right">
                              שם משפחה
                              <TextField value={this.state.lastName_search} onChange={(e) => this.setState({lastName_search: e.target.value}, () => { this.searchClients() })}/>
                            </TableCell>
                            <TableCell align="right">
                              שם פרטי
                              <TextField value={this.state.firstName_search} onChange={(e) => this.setState({firstName_search: e.target.value}, () => { this.searchClients() })}/>
                            </TableCell>
                            <TableCell align="right">
                              קוד לקוח
                            </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.table.map((row, index) => {
                          return (
                            <TableRow key={index} style={{backgroundColor: row.Clientid === this.state.chosen ? '#add8e6' : ''}} onClick={(e) => this.setClient(row)}>
                              <TableCell TableCell align="right" component="th" scope="row">{row.cStatus}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.paymentForm === '' ? '-' : row.paymentForm}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.paymentDay === '' ? 1 : row.groupingInvoices}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.phoneNumber}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.lastName}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.firstName}</TableCell>
                              <TableCell TableCell align="right" component="th" scope="row">{row.CustomClientID}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                </div>
              )}
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Clients;
