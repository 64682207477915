import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContent from '../components/DrawerContent';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const headers = {
  name: 'שם',
  amountWithVat: 'סכום כולל מע"מ',
  fromDate: 'מתאריך',
};

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      table: [],
      originalTable: [],
      openCreation: false,
      loading: false,
      Itemid: '',
      branchCode: '',
      branchName: '',
      branchAddress: '',
      branchCity: '',
      branchPostCode: '',
      deleteToast: false,
      sortBy: '',
      fromDate: '',
      openFromDate: false,
      history: [],
      historyOpen: false,
      openRegCreation: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  getData = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/items', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        let data = res.data.data;
        data = this.groupBy(data, 'name')
        this.setState({table: data, originalTable: data, loading: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  delete = (id) => {
    this.setState({loading: true, deleteToast: false}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/items?id='+id, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({loading: false, deleteToast: true});
        this.reset();
        this.setState({historyOpen: false});
        this.getData();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  update = (row) => {
    this.setState({updateToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/items-name?source='+row.name, {
        name: this.state.name,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({updateToast: true, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  updateItem = (id, row) => {
    this.setState({updateToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/items?id='+row.Itemid, {
        name: row.name,
        amountWithVat: this.state.amountWithVat,
        fromDate: this.state.fromDate,
        toDate: row.toDate,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({updateToast: true, loading: false});
        this.getData();
        this.setState({historyOpen: false});
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  create = (row) => {
    if (this.state.name === '' && this.state.amountWithVat >= 1) {
      alert('חובה למלא שם ושהסכום יהיה גדול מ0');
    } else {
      this.setState({createToast: false, loading: true}, () => {
        const token = window.localStorage.getItem('token');
        return axios.post('http://api.sivan-cma.com/v1/items', {
          name: this.state.name,
          amountWithVat: this.state.amountWithVat,
          fromDate: this.state.fromDate,
          toDate: '',
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
          this.setState({historyOpen: false, createToast: true, openCreation: false, loading: false});
          this.getData();
          this.reset();
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      });
    }
  }

  edit = (id, name) => {
    this.setState({
      'name': name,
      'chosen': id
    });
  }

  editAll = (row) => {
    this.setState({
      chosen: row.Itemid,
      fromDate: row.fromDate,
      amountWithVat: row.amountWithVat
    })
  }

  reset = (row) => {
    this.setState({
      'chosen': '',
      'Itemid': '',
      'name': '',
      'amountWithVat': '',
      'fromDate': '',
      'toDate': ''
    });
  }

  logout = () => {
    window.localStorage.setItem('token', '');
    window.location.reload();
  }

  resetSearch = () => {
    this.setState({search: '', table: this.state.originalTable})
  }

  search = (value) => {
    this.setState({search: value}, () => {
      if (value === '') this.setState({table: this.state.originalTable});
      else {
        let table_filtered = [];
        this.state.originalTable.forEach((item, i) => {
          try {
            if (
              value.includes(item.name) ||
              value.includes(item.amountWithVat) ||
              value.includes(item.fromDate) ||
              value.includes(item.toDate)
            ) {
              table_filtered.push(item);
            }
          } catch (err) {
            console.log(err);
          }
        });
        this.setState({table: table_filtered});
      }
    });
  }

  showHistory = (name) => {
    let data = this.state.table;
    let output = data[name];
    console.log(output);
    this.setState({history: output, historyOpen: true});
  }

  copyArrayOfObjects = (arr) => {
    let objs = [];
    arr.forEach((item, i) => {
      objs.push(item);
    });
    return objs;
  }

  setCreation = () => {
    const item = this.state.history[0];
    this.setState({
      openCreation: true,
      name: item.name,
      fromDate: new Date(Date.now()),
      toDate: '',
      amountWithVat: 0
    });
  }

  setRegCreation = () => {
    this.setState({
      openRegCreation: true,
      name: '',
      fromDate: new Date(Date.now()),
      toDate: '',
      amountWithVat: 0
    });
  }

  render() {
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}


            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <br />

            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <h4 style={{marginBottom: 0}}><b>ניהול פרטים</b></h4>

            <div style={{textAlign: 'right', padding: '1%', marginTop: 0}}>
              <a href="" style={{color: 'red', textDecoration: 'underline'}} onClick={(e) => this.logout()}>התנתק</a>
            </div>
            <div style={{textAlign: 'left', padding: '1%', marginTop: '-2%'}}>
              <a href="/" style={{color: 'blue', textDecoration: 'underline'}}>חזרה לעמוד הבית</a>
            </div><br />
            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div>
                {this.state.openCreation && (
                  <div>
                  <h4>הוספת רשומה חדשה</h4>
                  <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          {Object.keys(headers).map((item) => {
                            return (
                              <TableCell align="right">{headers[item]}</TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={'1_creation'}>
                          <TableCell component="th" scope="row" align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.create()}>יצירה</Button></TableCell>
                          <TableCell component="th" scope="row" align="right">{this.state.history[0].name}</TableCell>
                          <TableCell component="th" scope="row" align="right"><TextField label={headers['amountWithVat']} value={this.state.amountWithVat} onChange={(e) => this.setState({amountWithVat: e.target.value})}/></TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justify="space-around">
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="dd/MM/yyyy"
                                    label="מתאריך"
                                    value={new Date(this.state.fromDate)}
                                    onChange={(date) => this.setState({fromDate: date})}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    open={this.state.openFromDate}
                                    onClick={(e) => this.setState({openFromDate: true})}
                                    onClose={(e) => this.setState({openFromDate: false})}
                                  />
                                </Grid>
                            </MuiPickersUtilsProvider>
                          </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  </div>
                )}

                <div>
                  {this.state.historyOpen && (
                    <>
                    <Button onClick={(e) => this.setState({historyOpen: false})} variant={'contained'} color={'secondary'}>חזור</Button>
                    <h4>
                    {this.state.openCreation ? (
                      <Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({openCreation: false}, () => {this.reset()})}>הסתר הוספה</Button>
                    ) : (
                      <Button color={'primary'} variant={'contained'} onClick={(e) => this.setCreation(this.state.history)}>+ הוסף</Button>
                    )}
                    &nbsp;
                    היסטוריית מוצר - {this.state.history[0].name}
                    </h4>
                    <br />
                    <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {this.state.chosen === '' ? (
                              <>
                              {Object.keys(headers).map((item) => {
                                  return (
                                    <TableCell align="right">{headers[item]}</TableCell>
                                  );
                              })}
                              </>
                            ) : (
                              <>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>

                              {Object.keys(headers).map((item) => {
                                if (item !== "name") {
                                  return (
                                    <TableCell align="right">{headers[item]}</TableCell>
                                  );
                                }
                              })}
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.history.map((row) => {
                            if (this.state.chosen === row.Itemid) {
                              return (
                                <TableRow key={'1_creation'}>
                                  <TableCell component="th" scope="row" align="right"><Button color={'secondary'} variant={'contained'} onClick={(e) => this.delete(row.Itemid)}>מחק</Button></TableCell>
                                  <TableCell component="th" scope="row" align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({chosen: ''})}>ביטול</Button></TableCell>
                                  <TableCell component="th" scope="row" align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.updateItem(row.Itemid, row)}>עדכון</Button></TableCell>
                                  <TableCell component="th" scope="row" align="right"><TextField label={headers['amountWithVat']} value={this.state.amountWithVat} onChange={(e) => this.setState({amountWithVat: e.target.value})}/></TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <Grid container justify="space-around">
                                          <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="dd/MM/yyyy"
                                            label="מתאריך"
                                            value={new Date(this.state.fromDate)}
                                            onChange={(date) => this.setState({fromDate: date})}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                            }}
                                            open={this.state.openFromDate}
                                            onClick={(e) => this.setState({openFromDate: true})}
                                            onClose={(e) => this.setState({openFromDate: false})}
                                          />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                  </TableRow>
                              );
                            } else {
                              return (
                                <TableRow key={'1_creation'}>
                                <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.editAll(row)}>עריכה</Button></TableCell>
                                  <TableCell align="right">{row.amountWithVat}</TableCell>
                                  <TableCell TableCell align="right" component="th" scope="row">{new Date(row.fromDate).getDate() + '/' + (new Date(row.fromDate).getMonth() + 1) + '/' + new Date(row.fromDate).getFullYear()}</TableCell>
                                </TableRow>
                              );
                            }

                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    </>
                  )}
                </div>

                {this.state.openRegCreation ? (
                  <Button color={'primary'} variant={'contained'} style={{float: 'left', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.setState({openRegCreation: false}, () => {this.reset()})}>הסתר הוספה</Button>
                ) : (
                  <Button color={'primary'} variant={'contained'} style={{float: 'left', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.setRegCreation()}>+ הוסף</Button>
                )}

                {this.state.openRegCreation && (
                  <div>
                  <h4>הוספת רשומה חדשה</h4>
                  <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          {Object.keys(headers).map((item) => {
                            return (
                              <TableCell align="right">{headers[item]}</TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={'1_creation'}>
                          <TableCell component="th" scope="row" align="right"><Button color={'primary'} variant={'contained'} onClick={(e) => this.create()}>יצירה</Button></TableCell>
                          <TableCell component="th" scope="row" align="right"><TextField label={headers['name']} value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/></TableCell>
                          <TableCell component="th" scope="row" align="right"><TextField label={headers['amountWithVat']} value={this.state.amountWithVat} onChange={(e) => this.setState({amountWithVat: e.target.value})}/></TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justify="space-around">
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="dd/MM/yyyy"
                                    label="מתאריך"
                                    value={new Date(this.state.fromDate)}
                                    onChange={(date) => this.setState({fromDate: date})}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    open={this.state.openFromDate}
                                    onClick={(e) => this.setState({openFromDate: true})}
                                    onClose={(e) => this.setState({openFromDate: false})}
                                  />
                                </Grid>
                            </MuiPickersUtilsProvider>
                          </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  </div>
                )}

                <TableContainer component={Paper} style={{maxWidth: '100%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        {Object.keys(headers).map((item) => {
                          return (
                            <TableCell align="right">{headers[item]}</TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(this.state.table).map((item) => {
                        let rows = this.state.table[item];
                        let row = rows.sort(function(a, b) { return new Date(a.fromDate) - new Date(b.fromDate) }).slice(-1)[0];
                        try {
                          if (this.state.chosen === row.Itemid) {
                            return (
                              <TableRow key={row.name}>
                                <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.setState({chosen: ''})}>בטל</Button></TableCell>
                                <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.update(row)}>שמור</Button></TableCell>
                                <TableCell component="th" scope="row" align="right"><TextField label={headers['name']} value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/></TableCell>
                                <TableCell TableCell align="right" component="th" scope="row">{row.amountWithVat}</TableCell>
                                <TableCell TableCell align="right" component="th" scope="row">{new Date(row.fromDate).getDate() + '/' + (new Date(row.fromDate).getMonth() + 1) + '/' + new Date(row.fromDate).getFullYear()}</TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <TableRow key={row.name}>
                                <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.showHistory(row.name)}>היסטוריה</Button></TableCell>
                                <TableCell TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.edit(row.Itemid, row.name)}>ערוך</Button></TableCell>
                                <TableCell TableCell align="right" component="th" scope="row">{row.name}</TableCell>
                                <TableCell TableCell align="right" component="th" scope="row">{row.amountWithVat}</TableCell>
                                <TableCell TableCell align="right" component="th" scope="row">{new Date(row.fromDate).getDate() + '/' + (new Date(row.fromDate).getMonth() + 1) + '/' + new Date(row.fromDate).getFullYear()}</TableCell>
                              </TableRow>
                            );
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Items;
