import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContent from '../components/DrawerContent';

const headers = {
  bankName: 'שם הבנק',
  bankNumber: 'מספר הבנק',
};

class Vat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      vat: '17'
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/vat', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        let d = res.data.data;
        this.setState({data: d, loading: false}, () => {
          if (this.state.data.length >= 1) this.setState({vat: d[0]['vat']})
        });
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  delete = (row) => {
    this.setState({loading: true, deleteToast: false}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/vat', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({loading: false, deleteToast: true});
        this.reset();
        this.getData();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  update = (row) => {
    this.setState({updateToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/vat', {
        vat: this.state.vat,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({updateToast: true, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  create = (row) => {
    this.setState({createToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.post('http://api.sivan-cma.com/v1/vat', {
        vat: this.state.vat,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({createToast: true, openCreation: false, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  logout = () => {
    window.localStorage.setItem('token', '');
    window.location.reload();
  }

  render() {
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}


            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <br />

            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <h4 style={{marginBottom: 0}}><b>ניהול מע״מ</b></h4>
            <div style={{textAlign: 'right', padding: '1%', marginTop: 0}}>
              <a href="" style={{color: 'red', textDecoration: 'underline'}} onClick={(e) => this.logout()}>התנתק</a>
            </div>
            <div style={{textAlign: 'left', padding: '1%', marginTop: '-2%'}}>
              <a href="/" style={{color: 'blue', textDecoration: 'underline'}}>חזרה לעמוד הבית</a>
            </div><br />
            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div>
                {this.state.data.length === 0 && (
                  <>
                    <Button onClick={(e) => this.create()} color={'primary'} variant={'contained'}>שמירה</Button>
                    &nbsp;
                    <TextField style={{textAlign: 'right', direction: 'rtl'}} onChange={(e) => this.setState({vat: e.target.value})} value={this.state.vat} />
                  </>
                )}
                {this.state.data.length >= 1 && (
                  <>
                    <Button onClick={(e) => this.update()} color={'primary'} variant={'contained'}>שמירה</Button>
                    &nbsp;
                    <TextField style={{textAlign: 'right', direction: 'rtl'}} onChange={(e) => this.setState({vat: e.target.value})} value={this.state.vat} />
                  </>
                )}
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Vat;
