import React, { Component } from 'react';
import '../App.css';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import axios from 'axios';

class DrawerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  render() {
    return (
      <>
        <h1>:תפריט &nbsp;</h1>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/home'>עמוד הבית</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/full-table'>לטבלה המלאה</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/clients'>לקוחות</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/vat'>מע״מ</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/swap'>החלפת ערכים</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/banks'>ניהול רשימת בנקים</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/cities'>ניהול רשימת ערים</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/items'>ניהול פריטים</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/colors'>ניהול צבעים</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/group-values'>קבוצת ערכים</a><br />
        </div>
        <div className="option_border" />
        <div className="drawer_option">
          <a href='/logout'>התנתק</a><br />
        </div>
        <div className="option_border" />
      </>
    );
  }
}

export default DrawerContent;
