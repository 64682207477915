import React, { Component } from 'react';
import '../App.css';
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from '../components/DrawerContent';

const headers = {
  'Id': 'Id',
  'FirstName': 'FirstName',
  'LastName': 'LastName',
  'Printing': 'Printing',
  'Backup': 'Backup',
  'AllowStudentChanges': 'AllowStudentChanges',
  'AllowEventChanges': 'AllowEventChanges',
  'backupVersion': 'backupVersion',
  'status': 'status',
  'City': 'City',
  'ClientStatusMessage': 'ClientStatusMessage',
  'UserDeviceNumber': 'UserDeviceNumber',
  'HideCharts': 'HideCharts',
  'ProfessionalManager': 'ProfessionalManager',
  'ConvertInvoicestoExcel': 'ConvertInvoicestoExcel',
  'ReportsMenu': 'ReportsMenu',
  'WorkSummary': 'WorkSummary',
  'Bookkeeping': 'Bookkeeping',
  'BTAddress': 'BTAddress',
  'SerialNumber': 'SerialNumber',
  'UseNewBackupServer': 'UseNewBackupServer',
  'OpeningStudent': 'OpeningStudent',
  'OpenAgreement': 'OpenAgreement',
  'Settings': 'Settings',
  'ManagementTools': 'ManagementTools',
  'MaximumStudents': 'MaximumStudents',
  'MaximumRecordsBook': 'MaximumRecordsBook',
  'DatedToNoneDisplaySave': 'DatedToNoneDisplaySave',
  'OldCalendar': 'OldCalendar',
  'TerminalName': 'TerminalName',
  'Lectures': 'Lectures',
  'SivanSchool': 'Sivan-School'
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: '',
      updateToast: false,
      active: [],
      inactive: [],
      table: [],
      originalTable: [],
      openCreation: false,
      deleteToast: false,
      loading: false,
      column: 'AllowStudentChanges',
      target: '',
      source: '',
      swapOpen: false,
      openDrawer: false,
      showActive: true,
      fullTable: [],
      haveNextPage: true,
      havePreviousPage: true,
      page: 0,
      active_ids: [],
      inactive_ids: []
    };
  }

  componentDidMount() {
    this.getData();
    this.getColors();
  }

  split = (arr) => {
    let len = 10;
    let chunks = [],
        i = 0,
        n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }

  getData = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/client', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        const table = res.data.data.reverse();
        let active = [];
        let active_ids = [];
        let inactive = [];
        let inactive_ids = [];
        table.forEach((item, i) => {
          let status = item.status;
          if (item.status === "1" || status === 1) {
             active.push(item);
             if (!active_ids.includes(item.Id)) active_ids.push(item.Id)
          }
          else {
            if (!inactive_ids.includes(item.Id)) {
              inactive_ids.push(item.Id);
            }
            inactive.push(item);

          }
        });
        this.setState({inactive_ids: inactive_ids, active_ids: active_ids, page: 0, active: active, inactive: inactive, table: active, originalTable: res.data.data.reverse(), loading: false});
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  getColors = () => {
    this.setState({loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.get('http://api.sivan-cma.com/v1/colors', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        const colors = res.data.data;
        let colorsByKeys = {};
        colors.forEach((item, i) => {
          colorsByKeys[item.name] = item.color;
        });

        this.setState({loading: false, colors: colorsByKeys})
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  delete = (row) => {
    this.setState({loading: true, deleteToast: false}, () => {
      const token = window.localStorage.getItem('token');
      return axios.delete('http://api.sivan-cma.com/v1/client?rowid='+row.Rowid, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({loading: false, deleteToast: true});
        this.reset();
        this.getData();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false})
      });
    });
  }

  update = (row) => {
    this.setState({updateToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.put('http://api.sivan-cma.com/v1/client?rowid='+row.Rowid, {
        'Id': this.state.Id,
        'FirstName': this.state.FirstName,
        'LastName': this.state.LastName,
        'Printing': this.state.Printing,
        'Backup': this.state.Backup,
        'AllowStudentChanges': this.state.AllowStudentChanges,
        'AllowEventChanges': this.state.AllowEventChanges,
        'backupVersion': this.state.backupVersion,
        'status': this.state.status,
        'City': this.state.City,
        'ClientStatusMessage': this.state.ClientStatusMessage,
        'UserDeviceNumber': this.state.UserDeviceNumber,
        'HideCharts': this.state.HideCharts,
        'ProfessionalManager': this.state.ProfessionalManager,
        'ConvertInvoicestoExcel': this.state.ConvertInvoicestoExcel,
        'ReportsMenu': this.state.ReportsMenu,
        'WorkSummary': this.state.WorkSummary,
        'Bookkeeping': this.state.Bookkeeping,
        'BTAddress': this.state.BTAddress,
        'SerialNumber': this.state.SerialNumber,
        'UseNewBackupServer': this.state.UseNewBackupServer,
        'OpeningStudent': this.state.OpeningStudent,
        'OpenAgreement': this.state.OpenAgreement,
        'Settings':this.state.Settings,
        'ManagementTools': this.state.ManagementTools,
        'MaximumStudents': this.state.MaximumStudents,
        'MaximumRecordsBook': this.state.MaximumRecordsBook,
        'DatedToNoneDisplaySave': this.state.DatedToNoneDisplaySave,
        'OldCalendar': this.state.OldCalendar,
        'TerminalName': this.state.TerminalName,
        'Lectures': this.state.Lectures,
        'SivanSchool': this.state.SivanSchool
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({updateToast: true, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  create = (row) => {
    this.setState({createToast: false, loading: true}, () => {
      const token = window.localStorage.getItem('token');
      return axios.post('http://api.sivan-cma.com/v1/client', {
        'Id': this.state.Id,
        'FirstName': this.state.FirstName,
        'LastName': this.state.LastName,
        'Printing': this.state.Printing,
        'Backup': this.state.Backup,
        'AllowStudentChanges': this.state.AllowStudentChanges,
        'AllowEventChanges': this.state.AllowEventChanges,
        'backupVersion': this.state.backupVersion,
        'status': this.state.status,
        'City': this.state.City,
        'ClientStatusMessage': this.state.ClientStatusMessage,
        'UserDeviceNumber': this.state.UserDeviceNumber,
        'HideCharts': this.state.HideCharts,
        'ProfessionalManager': this.state.ProfessionalManager,
        'ConvertInvoicestoExcel': this.state.ConvertInvoicestoExcel,
        'ReportsMenu': this.state.ReportsMenu,
        'WorkSummary': this.state.WorkSummary,
        'Bookkeeping': this.state.Bookkeeping,
        'BTAddress': this.state.BTAddress,
        'SerialNumber': this.state.SerialNumber,
        'UseNewBackupServer': this.state.UseNewBackupServer,
        'OpeningStudent': this.state.OpeningStudent,
        'OpenAgreement': this.state.OpenAgreement,
        'Settings':this.state.Settings,
        'ManagementTools': this.state.ManagementTools,
        'MaximumStudents': this.state.MaximumStudents,
        'MaximumRecordsBook': this.state.MaximumRecordsBook,
        'DatedToNoneDisplaySave': this.state.DatedToNoneDisplaySave,
        'OldCalendar': this.state.OldCalendar,
        'TerminalName': this.state.TerminalName,
        'Lectures': this.state.Lectures,
        'SivanSchool': this.state.SivanSchool
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        this.setState({createToast: true, openCreation: false, loading: false});
        this.getData();
        this.reset();
      }).catch(err => {
        console.log(err);
        this.setState({loading: false});
      });
    });
  }

  edit = (row) => {
    this.setState({
      'Id': row.Id,
      'FirstName': row.FirstName,
      'LastName': row.LastName,
      'Printing': row.Printing,
      'Backup': row.Backup,
      'AllowStudentChanges': row.AllowStudentChanges,
      'AllowEventChanges': row.AllowEventChanges,
      'backupVersion': row.backupVersion,
      'status': row.status,
      'City': row.City,
      'ClientStatusMessage': row.ClientStatusMessage,
      'UserDeviceNumber': row.UserDeviceNumber,
      'HideCharts': row.HideCharts,
      'ProfessionalManager': row.ProfessionalManager,
      'ConvertInvoicestoExcel': row.ConvertInvoicestoExcel,
      'ReportsMenu': row.ReportsMenu,
      'WorkSummary': row.WorkSummary,
      'Bookkeeping': row.Bookkeeping,
      'BTAddress': row.BTAddress,
      'SerialNumber': row.SerialNumber,
      'UseNewBackupServer': row.UseNewBackupServer,
      'OpeningStudent': row.OpeningStudent,
      'OpenAgreement': row.OpenAgreement,
      'Settings':row.Settings,
      'ManagementTools': row.ManagementTools,
      'MaximumStudents': row.MaximumStudents,
      'MaximumRecordsBook': row.MaximumRecordsBook,
      'DatedToNoneDisplaySave': row.DatedToNoneDisplaySave,
      'OldCalendar': row.OldCalendar,
      'TerminalName': row.TerminalName,
      'Lectures': row.Lectures,
      'SivanSchool': row.SivanSchool,
      'chosen': row.Rowid
    });
  }

  reset = (row) => {
    this.setState({
      'Id': '',
      'FirstName': '',
      'LastName': '',
      'Printing': '',
      'Backup': '',
      'AllowStudentChanges': '',
      'AllowEventChanges': '',
      'backupVersion': '',
      'status': '',
      'City': '',
      'ClientStatusMessage': '',
      'UserDeviceNumber': '',
      'HideCharts': '',
      'ProfessionalManager': '',
      'ConvertInvoicestoExcel': '',
      'ReportsMenu': '',
      'WorkSummary': '',
      'Bookkeeping': '',
      'BTAddress': '',
      'SerialNumber': '',
      'UseNewBackupServer': '',
      'OpeningStudent': '',
      'OpenAgreement': '',
      'Settings': '',
      'ManagementTools': '',
      'MaximumStudents': '',
      'MaximumRecordsBook': '',
      'DatedToNoneDisplaySave': '',
      'OldCalendar': '',
      'TerminalName': '',
      'Lectures': '',
      'SivanSchool': '',
      'chosen': '',
    });
  }

  logout = () => {
    window.localStorage.setItem('token', '');
    window.location.reload();
  }

  resetSearch = () => {
    this.setState({table: this.state.active, search: ''});
  }

  search = (value) => {
    this.setState({search: value}, () => {
      if (value === '') this.setState({table: this.state.active, page: 0, search: ''});
      else {
        let table_filtered = [];
        this.state.originalTable.forEach((item, i) => {
          try {
            if (
              item.Id === value ||
              item.FirstName === value ||
              item.LastName === value ||
              item.City === value ||
              item.ClientStatusMessage === value ||
              item.UserDeviceNumber === value ||
              item.BTAddress === value ||
              item.SerialNumber === value ||
              item.TerminalName === value
            ) {
              table_filtered.push(item);
            }
          } catch (err) {
            console.log(err);
          }
        });
        this.setState({page: 0, table: table_filtered});
      }
    });
  }

    swap = () => {
      this.setState({loading: true}, () => {
        const token = window.localStorage.getItem('token');
        return axios.put('http://api.sivan-cma.com/v1/client-swap', {
          column: this.state.column,
          target: this.state.target,
          source: this.state.source
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
          console.log(res);
          this.getData();
        }).catch(err => {
          console.log(err);
          this.setState({loading: false})
        });
      });
    }

    stringify = (text) => {
      if (text === "null" || text === null || text === undefined || text === "undefined") return '';
      return text;
    }

      sortBy = (sortBy) => {
        let data__ = this.state.fullTable;
        let data = [];
        data__.forEach((item, i) => {
          item.forEach((tfa) => {
            data.push(tfa);
          });
        });

        let data_ = data;
        this.setState({loading: true}, () => {
          if (sortBy === 'Id') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.Id) - parseInt(b.Id);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Id) - parseInt(a.Id);
              });
              this.setState({ sortBy: 'Id',})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_,});
          } else if (sortBy === 'Printing') {
            if (this.state.sortBy === 'Printing') {
              data_.sort(function (a, b) {
                return parseInt(a.Printing) - parseInt(b.Printing);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Printing) - parseInt(a.Printing);
              });
              this.setState({sortBy: 'Printing'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else if (sortBy === 'Backup') {
            if (this.state.sortBy === 'Backup') {
              data_.sort(function (a, b) {
                return parseInt(a.Backup) - parseInt(b.Backup);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Backup) - parseInt(a.Backup);
              });
              this.setState({sortBy: 'Backup'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'AllowStudentChanges') {
            if (this.state.sortBy === 'AllowStudentChanges') {
              data_.sort(function (a, b) {
                return new Date(a.AllowStudentChanges) - new Date(b.AllowStudentChanges);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return new Date(b.AllowStudentChanges) - new Date(a.AllowStudentChanges);
              });
              this.setState({sortBy: 'AllowStudentChanges'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'AllowEventChanges') {
            if (this.state.sortBy === 'AllowEventChanges') {
              data_.sort(function (a, b) {
                return new Date(a.AllowEventChanges) - new Date(b.AllowEventChanges);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return new Date(b.AllowEventChanges) - new Date(a.AllowEventChanges);
              });
              this.setState({sortBy: 'AllowEventChanges'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else if (sortBy === 'backupVersion') {
            if (this.state.sortBy === 'backupVersion') {
              data_.sort(function (a, b) {
                return parseInt(a.backupVersion) - parseInt(b.backupVersion);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.backupVersion) - parseInt(a.backupVersion);
              });
              this.setState({sortBy: 'backupVersion'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else if (sortBy === 'status') {
            if (this.state.sortBy === 'status') {
              data_.sort(function (a, b) {
                return parseInt(a.status) - parseInt(b.status);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.status) - parseInt(a.status);
              });
              this.setState({sortBy: 'status'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'UserDeviceNumber') {
            if (this.state.sortBy === 'UserDeviceNumber') {
              data_.sort(function (a, b) {
                return parseInt(a.UserDeviceNumber) - parseInt(b.UserDeviceNumber);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.UserDeviceNumber) - parseInt(a.UserDeviceNumber);
              });
              this.setState({sortBy: 'UserDeviceNumber'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else if (sortBy === 'HideCharts') {
            if (this.state.sortBy === 'HideCharts') {
              data_.sort(function (a, b) {
                return parseInt(a.HideCharts) - parseInt(b.HideCharts);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.HideCharts) - parseInt(a.HideCharts);
              });
              this.setState({sortBy: 'HideCharts'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'ReportsMenu') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.ReportsMenu) - parseInt(b.ReportsMenu);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.ReportsMenu) - parseInt(a.ReportsMenu);
              });
              this.setState({sortBy: 'ReportsMenu'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'WorkSummary') {
            if (this.state.sortBy === 'WorkSummary') {
              data_.sort(function (a, b) {
                return parseInt(a.WorkSummary) - parseInt(b.WorkSummary);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.WorkSummary) - parseInt(a.WorkSummary);
              });
              this.setState({sortBy: 'WorkSummary'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'Bookkeeping') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.Bookkeeping) - parseInt(b.Bookkeeping);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Bookkeeping) - parseInt(a.Bookkeeping);
              });
              this.setState({ sortBy: 'Bookkeeping'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_,});
          } else if (sortBy === 'UseNewBackupServer') {
            if (this.state.sortBy === 'Id') {
              data_.sort(function (a, b) {
                return parseInt(a.UseNewBackupServer) - parseInt(b.UseNewBackupServer);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.UseNewBackupServer) - parseInt(a.UseNewBackupServer);
              });
              this.setState({sortBy: 'UseNewBackupServer'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_, });
          } else if (sortBy === 'MaximumStudents') {
            if (this.state.sortBy === 'MaximumStudents') {
              data_.sort(function (a, b) {
                return parseInt(a.Id) - parseInt(b.Id);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.Id) - parseInt(a.Id);
              });
              this.setState({sortBy: 'MaximumStudents'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else if (sortBy === 'MaximumRecordsBook') {
            if (this.state.sortBy === 'MaximumRecordsBook') {
              data_.sort(function (a, b) {
                return parseInt(a.MaximumRecordsBook) - parseInt(b.MaximumRecordsBook);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return parseInt(b.MaximumRecordsBook) - parseInt(a.MaximumRecordsBook);
              });
              this.setState({sortBy: 'MaximumRecordsBook'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else if (sortBy === 'DatedToNoneDisplaySave') {
            if (this.state.sortBy === 'DatedToNoneDisplaySave') {
              data_.sort(function (a, b) {
                return new Date(a.DatedToNoneDisplaySave) - new Date(b.DatedToNoneDisplaySave);
              });
              this.setState({sortBy: ''})
            } else {
              data_.sort(function (a, b) {
                return new Date(b.DatedToNoneDisplaySave) - new Date(a.DatedToNoneDisplaySave);
              });
              this.setState({sortBy: 'DatedToNoneDisplaySave'})
            }
            this.setState({loading: false, fullTable: this.split(data_), table: data_});
          } else {
            console.log('?')
          }
        })
      }

      whatColor = (row) => {
        try {
          if (row.Backup === 0 || row.Backup === "0") return this.state.colors['Backup'];

          if (row.Printing === 0 || row.Printing === "0") return this.state.colors['Printing'];

          return '';
        } catch (err) {
          return '';
        }
      }

      nextPage = () => {
        let page = this.state.page;
        let chunks = this.state.fullTable.length;
        if (chunks > page + 1) {
          page += 1;
          this.setState({havePreviousPage: true, haveNextPage: chunks > page + 1, page: page, table: this.state.fullTable[page]});
        } else {
          this.setState({havePreviousPage: true, haveNextPage: false})
        }
      }

      previousPage = () => {
        let page = this.state.page;
        let chunks = this.state.fullTable.length;
        if (chunks > page - 1) {
          page -= 1;
          this.setState({haveNextPage: true, havePreviousPage: chunks > page - 1, page: page, table: this.state.fullTable[page]});
        } else {
          this.setState({haveNextPage: true, havePreviousPage: false})
        }
      }

  render() {
    const allowSortBy = [
      'Id',
      'Printing',
      'Backup',
      'backupVersion',
      'status',
      'UserDeviceNumber',
      'HideCharts',
      'ReportsMenu',
      'WorkSummary',
      'Bookkeeping',
      'UseNewBackupServer',
      'MaximumStudents',
      'MaximumRecordsBook',
    ]
    return (
      <div className="App">
        <br /><br /><br />
        <center>
          <Card className="body_card">
            {this.state.updateToast && (<Toast type="success">הרשומה עודכנה בהצלחה</Toast>)}
            {this.state.createToast && (<Toast type="success">הרשומה נוצרה בהצלחה</Toast>)}
            {this.state.deleteToast && (<Toast type="success">הרשומה נמחקה בהצלחה</Toast>)}

            <Drawer anchor={'right'} open={this.state.openDrawer} onClose={() => this.setState({openDrawer: false})}>
            <div
              role="presentation right_drawer"
              onClick={() => this.setState({openDrawer: false})}
              onKeyDown={() => this.setState({openDrawer: false})}
            >
              <DrawerContent />
            </div>
            </Drawer>

            <div style={{width: '100%', justifyContent: 'flex-end'}}>
              <a href="#" style={{textDecoration: 'none', color: 'black'}} onClick={(e) => this.setState({openDrawer: true})}><MenuIcon style={{textAlign: 'right', float: 'right', marginRight: '1%'}} fontSize="large" /></a>
            </div>
            <h2 style={{marginBottom: 0}}><b>Sivan Client | ניהול סיון</b></h2>
            <p>סה"כ פעילים {this.state.active_ids.length} | סה"כ לא פעילים {this.state.inactive_ids.length}</p>

            {this.state.openCreation ? (
              <Button color={'primary'} variant={'contained'} style={{marginTop: '-7%', marginLeft: '2%', float: 'left',}} onClick={(e) => this.setState({openCreation: false}, () => {this.reset()})}>הסתר הוספה</Button>
            ) : (
              <Button color={'primary'} variant={'contained'} style={{marginTop: '-7%', marginLeft: '2%', float: 'left',}} onClick={(e) => this.setState({openCreation: true}, () => {this.reset()})}>+ הוסף</Button>
            )}


            {(this.props.loading || this.state.loading) ? (
              <Loading />
            ) : (
              <div>
                {this.state.openCreation && (
                  <div>
                  <h4>הוספת רשומה חדשה</h4>
                  <TableContainer component={Paper} style={{maxWidth: '97%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          {Object.keys(headers).map((item) => {
                            return (
                              <TableCell align="right">{headers[item]}</TableCell>
                            );
                          })}
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={'1_creation'}>
                          <TableCell align="right" component="th" scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.create()}>יצירה</Button></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="Id" value={this.state.Id} onChange={(e) => this.setState({Id: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="FirstName" value={this.state.FirstName} onChange={(e) => this.setState({FirstName: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="LastName" value={this.state.LastName} onChange={(e) => this.setState({LastName: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="Printing" value={this.state.Printing} onChange={(e) => this.setState({Printing: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="Backup" value={this.state.Backup} onChange={(e) => this.setState({Backup: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="AllowStudentChanges" value={this.state.AllowStudentChanges} onChange={(e) => this.setState({AllowStudentChanges: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="AllowEventChanges" value={this.state.AllowEventChanges} onChange={(e) => this.setState({AllowEventChanges: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="backupVersion" value={this.state.backupVersion} onChange={(e) => this.setState({backupVersion: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="status" value={this.state.status} onChange={(e) => this.setState({status: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="City" value={this.state.City} onChange={(e) => this.setState({City: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="ClientStatusMessage" value={this.state.ClientStatusMessage} onChange={(e) => this.setState({ClientStatusMessage: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="UserDeviceNumber" value={this.state.UserDeviceNumber} onChange={(e) => this.setState({UserDeviceNumber: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="HideCharts" value={this.state.HideCharts} onChange={(e) => this.setState({HideCharts: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="ProfessionalManager" value={this.state.ProfessionalManager} onChange={(e) => this.setState({ProfessionalManager: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="ConvertInvoicestoExcel" value={this.state.ConvertInvoicestoExcel} onChange={(e) => this.setState({ConvertInvoicestoExcel: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="ReportsMenu" value={this.state.ReportsMenu} onChange={(e) => this.setState({ReportsMenu: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="WorkSummary" value={this.state.WorkSummary} onChange={(e) => this.setState({WorkSummary: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="Bookkeeping" value={this.state.Bookkeeping} onChange={(e) => this.setState({Bookkeeping: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="BTAddress" value={this.state.BTAddress} onChange={(e) => this.setState({BTAddress: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="SerialNumber" value={this.state.SerialNumber} onChange={(e) => this.setState({SerialNumber: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="UseNewBackupServer" value={this.state.UseNewBackupServer} onChange={(e) => this.setState({UseNewBackupServer: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="OpeningStudent" value={this.state.OpeningStudent} onChange={(e) => this.setState({OpeningStudent: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="OpenAgreement" value={this.state.OpenAgreement} onChange={(e) => this.setState({OpenAgreement: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="Settings" value={this.state.Settings} onChange={(e) => this.setState({Settings: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="ManagementTools" value={this.state.ManagementTools} onChange={(e) => this.setState({ManagementTools: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="MaximumStudents" value={this.state.MaximumStudents} onChange={(e) => this.setState({MaximumStudents: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="MaximumRecordsBook" value={this.state.MaximumRecordsBook} onChange={(e) => this.setState({MaximumRecordsBook: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="DatedToNoneDisplaySave" value={this.state.DatedToNoneDisplaySave} onChange={(e) => this.setState({DatedToNoneDisplaySave: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="OldCalendar" value={this.state.OldCalendar} onChange={(e) => this.setState({OldCalendar: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="TerminalName" value={this.state.TerminalName} onChange={(e) => this.setState({TerminalName: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="Lectures" value={this.state.Lectures} onChange={(e) => this.setState({Lectures: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row"><TextField label="SivanSchool" value={this.state.SivanSchool} onChange={(e) => this.setState({SivanSchool: e.target.value})}/></TableCell>
                          <TableCell align="right" component="th" scope="row">&nbsp; <Button color={'primary'} variant={'contained'} onClick={(e) => this.create()}>יצירה</Button></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  </div>
                )}

                {this.state.swapOpen && (
                  <div style={{border: '1px solid black'}}>
                    <div>
                      <a href="#"><p style={{fontWeight: 'bold', textDecoration: 'underline'}} onClick={(e) => this.setState({swapOpen: false})}>הסתר</p></a>
                      <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>החלף ערכים</p>

                      <p>בחר עמודה</p>
                      <Select placeholder={'עמודה'} label="עמודה" value={this.state.column} onChange={(e) => this.setState({column: e.target.value})}>
                        <MenuItem value={'AllowStudentChanges'}>AllowStudentChanges</MenuItem>
                        <MenuItem value={'AllowEventChanges'}>AllowEventChanges</MenuItem>
                      </Select>
                      <p>ערכים</p>
                      <div>
                      <TextField style={{width: 100}} label="החלף ל" value={this.state.target} onChange={(e) => this.setState({target: e.target.value})} plcaeholder={'טקסט לחיפוש כאן..'} />
                      &nbsp;&nbsp;&nbsp;
                      <TextField style={{width: 100}} label="מקור" value={this.state.source} onChange={(e) => this.setState({source: e.target.value})} plcaeholder={'טקסט לחיפוש כאן..'} />
                      </div>
                      <Button style={{marginTop: 3, marginBottom: 6}} color={'secondary'} variant={'contained'} onClick={(e) => this.swap()}>החלף</Button>

                    </div>
                  </div>
                )}

                <div>
                  <p>חיפוש על פי טקסט</p>
                  <Button color={!this.state.showActive ? 'primary' : ''} variant={'contained'} onClick={(e) => this.setState({showActive: false,page:0, table: this.split(this.state.inactive)[0], fullTable: this.split(this.state.inactive)})}>הצג לא פעילים</Button>
                  &nbsp;&nbsp;
                  <Button onClick={(e) => this.resetSearch()}>איפוס</Button>
                  <TextField style={{width: 300}} value={this.state.search} onChange={(e) => this.search(e.target.value)} plcaeholder={'טקסט לחיפוש כאן..'} />
                  &nbsp;&nbsp;&nbsp;
                  <Button color={this.state.showActive ? 'primary' : ''} variant={'contained'} onClick={(e) => this.setState({showActive: true,page:0, table: this.split(this.state.active)[0], fullTable: this.split(this.state.active)})}>הצג פעילים</Button>
                </div>
                <br />
                <TableContainer component={Paper} style={{maxWidth: '100%', boxShadow: '0 6px 12px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)'}} className="tableFixHeader">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        {Object.keys(headers).map((item) => {
                          return (
                           <TableCell align="right" onClick={(e) => allowSortBy.includes(item) ? this.sortBy(item) : console.log('?')}><a style={{textDecoration: 'none', color: 'black'}} href="#">{allowSortBy.includes(item) && (<>{this.state.sortBy === item ? '↑' : '↓'}</>)}  {headers[item]}</a></TableCell>
                          );
                        })}
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.table.map((row) => {
                        if (this.state.chosen === row.Rowid) {
                          return (
                            <TableRow key={row.name}>
                              <TableCell component="th" align="right"  scope="row"><Button color={'secondary'} variant={'contained'} onClick={(e) => this.delete(row)}>מחק</Button></TableCell>
                              <TableCell component="th" align="right"  scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.update(row)}>שמור</Button></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="Id" value={this.state.Id} onChange={(e) => this.setState({Id: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="FirstName" value={this.state.FirstName} onChange={(e) => this.setState({FirstName: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="LastName" value={this.state.LastName} onChange={(e) => this.setState({LastName: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="Printing" value={this.state.Printing} onChange={(e) => this.setState({Printing: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="Backup" value={this.state.Backup} onChange={(e) => this.setState({Backup: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="AllowStudentChanges" value={this.state.AllowStudentChanges} onChange={(e) => this.setState({AllowStudentChanges: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="AllowEventChanges" value={this.state.AllowEventChanges} onChange={(e) => this.setState({AllowEventChanges: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="backupVersion" value={this.state.backupVersion} onChange={(e) => this.setState({backupVersion: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="status" value={this.state.status} onChange={(e) => this.setState({status: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="City" value={this.state.City} onChange={(e) => this.setState({City: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="ClientStatusMessage" value={this.state.ClientStatusMessage} onChange={(e) => this.setState({ClientStatusMessage: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="UserDeviceNumber" value={this.state.UserDeviceNumber} onChange={(e) => this.setState({UserDeviceNumber: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="HideCharts" value={this.state.HideCharts} onChange={(e) => this.setState({HideCharts: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="ProfessionalManager" value={this.state.ProfessionalManager} onChange={(e) => this.setState({ProfessionalManager: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="ConvertInvoicestoExcel" value={this.state.ConvertInvoicestoExcel} onChange={(e) => this.setState({ConvertInvoicestoExcel: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="ReportsMenu" value={this.state.ReportsMenu} onChange={(e) => this.setState({ReportsMenu: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="WorkSummary" value={this.state.WorkSummary} onChange={(e) => this.setState({WorkSummary: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="Bookkeeping" value={this.state.Bookkeeping} onChange={(e) => this.setState({Bookkeeping: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="BTAddress" value={this.state.BTAddress} onChange={(e) => this.setState({BTAddress: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="SerialNumber" value={this.state.SerialNumber} onChange={(e) => this.setState({SerialNumber: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="UseNewBackupServer" value={this.state.UseNewBackupServer} onChange={(e) => this.setState({UseNewBackupServer: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="OpeningStudent" value={this.state.OpeningStudent} onChange={(e) => this.setState({OpeningStudent: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="OpenAgreement" value={this.state.OpenAgreement} onChange={(e) => this.setState({OpenAgreement: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="Settings" value={this.state.Settings} onChange={(e) => this.setState({Settings: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="ManagementTools" value={this.state.ManagementTools} onChange={(e) => this.setState({ManagementTools: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="MaximumStudents" value={this.state.MaximumStudents} onChange={(e) => this.setState({MaximumStudents: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="MaximumRecordsBook" value={this.state.MaximumRecordsBook} onChange={(e) => this.setState({MaximumRecordsBook: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="DatedToNoneDisplaySave" value={this.state.DatedToNoneDisplaySave} onChange={(e) => this.setState({DatedToNoneDisplaySave: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="OldCalendar" value={this.state.OldCalendar} onChange={(e) => this.setState({OldCalendar: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="TerminalName" value={this.state.TerminalName} onChange={(e) => this.setState({TerminalName: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="Lectures" value={this.state.Lectures} onChange={(e) => this.setState({Lectures: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><TextField label="SivanSchool" value={this.state.SivanSchool} onChange={(e) => this.setState({SivanSchool: e.target.value})}/></TableCell>
                              <TableCell component="th" align="right"  scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.update(row)}>שמור</Button></TableCell>
                            </TableRow>
                          );
                        } else {
                          return (
                            <TableRow key={row.name} style={{backgroundColor: this.whatColor(row)}}>
                              <TableCell component="th" align="right"  scope="row"></TableCell>
                              <TableCell component="th" align="right"  scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.edit(row)}>ערוך</Button></TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.Id)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.FirstName)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.LastName)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.Printing)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.Backup)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.AllowStudentChanges)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.AllowEventChanges)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.backupVersion)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.status)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.City)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.ClientStatusMessage)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.UserDeviceNumber)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.HideCharts)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.ProfessionalManager)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.ConvertInvoicestoExcel)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.ReportsMenu)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.WorkSummary)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.Bookkeeping)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.BTAddress)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.SerialNumber)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.UseNewBackupServer)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.OpeningStudent)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.OpenAgreement)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.Settings)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.ManagementTools)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.MaximumStudents)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.MaximumRecordsBook)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.DatedToNoneDisplaySave)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.OldCalendar)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.TerminalName)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.Lectures)}</TableCell>
                              <TableCell component="th" align="right"  scope="row">{this.stringify(row.SivanSchool)}</TableCell>
                              <TableCell component="th" align="right"  scope="row"><Button color={'primary'} variant={'contained'} onClick={(e) => this.edit(row)}>ערוך</Button></TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </Card>
        </center>
      </div>
    );
  }
}

export default Home;
